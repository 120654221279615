import { Fragment, useCallback } from 'react';
import clsx from 'clsx';

import Route from '@/config/routes/types';
import { Link } from '@/components/common/link';

const menuLinks = [
  {
    title: 'Platform',
    to: Route.CONTACT_US
  },
  {
    title: 'Tanglius',
    href: '#about-capsule'
  },
  {
    title: 'How it works',
    href: '#about-workflow'
  },
  {
    title: 'Use cases',
    href: '#use-cases'
  },
  {
    title: 'Contact us',
    href: '#contact-us'
  }
];

const menuAgreements = [
  {
    title: 'Terms & Conditions',
    to: Route.TERMS_AND_CONDITIONS
  }
  // {
  //   title: 'Privacy',
  //   to: Route.PRIVACY
  // }
];

interface LandingLayoutSidebarMenuProps {
  isOpened: boolean;
  onClose: () => void;
}

export const LandingLayoutSidebarMenu = ({
  isOpened = false,
  onClose
}: LandingLayoutSidebarMenuProps) => {
  const handleClose = useCallback(onClose, [onClose]);

  return (
    <div className="flex flex-col gap-36 pt-8 px-7.75 pb-4">
      <div className="flex flex-col">
        {menuLinks.map(({ title, ...linkProps }, idx) => (
          <Fragment key={idx}>
            <Link
              classes={clsx(
                'truncate cursor-pointer hover:text-mantis transition-colors ease-out duration-300',
                isOpened ? 'text-opacity-100' : 'text-opacity-0'
              )}
              noUnderline
              onClick={handleClose}
              variant="primary"
              {...linkProps}
            >
              {title}
            </Link>
          </Fragment>
        ))}
      </div>

      <div className="flex flex-col">
        {menuAgreements.map(({ title, ...linkProps }, idx) => (
          <Fragment key={idx}>
            <Link
              classes={clsx(
                'truncate cursor-pointer hover:text-mantis transition-colors ease-out duration-300',
                isOpened ? 'text-opacity-100' : 'text-opacity-0'
              )}
              noUnderline
              onClick={handleClose}
              variant="secondary"
              {...linkProps}
            >
              {title}
            </Link>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
