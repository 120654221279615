import { ReactNode, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { useClickOutside } from '@/shared/hooks/use-click-outside';

export interface ModalFrameProps {
  backdrop?: boolean;
  children: ReactNode;
  color?: keyof typeof ModalFrameColor;
  classes?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  open?: boolean;
  onClose?: () => void;
  size?: keyof typeof ModalFrameSize;
  variant?: keyof typeof ModalFrameVariant;
}

const ModalFrameColor = {
  default: 'bg-white text-black',
  dark: 'bg-cinder text-white'
};

const ModalFrameSize = {
  sm: 'px-0',
  md: 'px-14'
};

const ModalFrameVariant = {
  default: '',
  rounded: 'rounded-semi-md'
};

export const ModalFrame = ({
  backdrop = true,
  children,
  color = 'default',
  classes,
  closeOnClickOutside,
  closeOnEsc,
  open,
  onClose,
  size = 'sm',
  variant = 'default'
}: ModalFrameProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    onClose?.();

    document.body.style.overflow = 'unset';
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        handleClose();
      }
    },
    [handleClose, open]
  );

  useEffect(() => {
    if (!closeOnEsc) return;

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [closeOnEsc, onKeyDown]);

  const handleClickOutside = useCallback(() => {
    if (!closeOnClickOutside) return;

    handleClose();
  }, [closeOnClickOutside, handleClose]);

  useClickOutside(ref, handleClickOutside);

  return (
    <>
      {backdrop && <div className="fixed inset-0 bg-black/80 z-backdrop" />}

      <div
        ref={ref}
        className={clsx(
          'z-10',
          ModalFrameSize[size],
          ModalFrameColor[color],
          ModalFrameVariant[variant],
          classes
        )}
      >
        {children}
      </div>
    </>
  );
};
