import clsx from 'clsx';

import { Container } from '@/components/common/container';
import { useWindowSize } from '@/shared/hooks/use-window-size';
import { LANDING_BAR_HEIGHT, LANDING_BAR_HEIGHT_MOBILE } from '../../state/constants';
import { LandingLayoutHeaderBrand } from './landing-layout-header-brand';
import { LandingLayoutHeaderExplore } from './landing-layout-header-explore';

export const LandingLayoutHeader = () => {
  const { width } = useWindowSize();
  const isLayoutCompact = width < 640;

  return (
    <>
      <Container
        classes={clsx(
          'w-full flex justify-end items-center bg-black-cow/50 sm:bg-transparent z-30',
          isLayoutCompact ? 'backdrop-blur-almost-md' : 'backdrop-blur-none px-7.75'
        )}
        style={{ height: isLayoutCompact ? LANDING_BAR_HEIGHT_MOBILE : LANDING_BAR_HEIGHT }}
        size="xs"
      >
        <LandingLayoutHeaderBrand />
      </Container>

      <LandingLayoutHeaderExplore />
    </>
  );
};
