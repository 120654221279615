import { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';

type RouteWithoutElement = Omit<RouteProps, 'element'>;

export interface IRouteChildren extends RouteWithoutElement {
  element: FunctionComponent;
  path: string;
}
export interface IRouteProps extends RouteWithoutElement {
  element: FunctionComponent;
  layout: FunctionComponent;
  childrens?: IRouteChildren[];
}

enum Route {
  ROOT = '/',
  CONTACT_US = '/contact-us',
  SUBSCRIBE = '/subscribe',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY = '/privacy'
}

export default Route;
