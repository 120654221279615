import { Button } from '@/components/common/button';
import { LoadingLayout } from '@/components/layout/loading-layout';
import Modal from '@/components/modal';
import { TextField } from '@/components/text-field';
import { useContactData, useContactModalData } from './state/hooks';

export const ContactPage = () => {
  const { setModalClosed } = useContactModalData();
  const { name, email, message, error, isLoading, setName, setEmail, setMessage, contact } =
    useContactData();

  return (
    <Modal.Frame
      classes="fixed inset-0 w-4/5 sm:w-2/3 md:w-3/5 xl:w-2/5 h-fit flex flex-col m-auto animate-slide-bottom-fast z-top"
      closeOnClickOutside
      closeOnEsc
      color="dark"
      id="contact-modal"
      onClose={setModalClosed}
      open
      backdrop={true}
      variant="rounded"
    >
      <LoadingLayout loading={isLoading} overlay>
        <Modal.Header size="xs" title="Contact Us" />

        <Modal.Body classes="flex flex-col gap-5 px-10" size="md">
          <div className="text-xs text-left text-mountain-mist font-mono font-bold tracking-dense">
            COMING SOON! Subscribe and be one of the first to join a creative & collaborative economy.
          </div>

          <div className="text-xs text-left text-mountain-mist font-mono font-bold tracking-dense">
            {/* COMING SOON. Subscribe and be one of the first to join a new experiential economy.  */}
            Tell us about your idea.
          </div>

          <div className="w-full">
            <TextField
              color="dark"
              error={Boolean(error.name)}
              helperText={error.name}
              id="contact-name"
              label="Name"
              onChange={setName}
              variant="outlined"
              value={name}
              size="sm"
            />
          </div>

          <div className="w-full">
            <TextField
              color="dark"
              error={Boolean(error.email)}
              helperText={error.email}
              id="contact-email"
              label="Email"
              onChange={setEmail}
              variant="outlined"
              value={email}
              size="sm"
            />
          </div>

          <div className="w-full">
            <TextField
              color="dark"
              error={Boolean(error.message)}
              helperText={error.message}
              id="contact-message"
              label="Message"
              maxLength={1000}
              onChange={setMessage}
              variant="outlined"
              value={message}
              size="sm"
              type="textarea"
            />
          </div>
        </Modal.Body>

        <Modal.Footer classes="flex gap-1">
          <Button classes="w-fit" size="sm" text="Cancel" onClick={setModalClosed} variant="text" />

          <Button classes="w-fit" size="sm" text="Submit" onClick={contact} />
        </Modal.Footer>
      </LoadingLayout>
    </Modal.Frame>
  );
};
