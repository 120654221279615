import { useCallback } from 'react';
import clsx from 'clsx';

import { Icon } from '../common/icon';
import { TIconNames } from '@/shared/types';

interface TextFieldIconProps {
  atEnd?: boolean;
  disabled?: boolean;
  error?: boolean;
  icon: TIconNames;
  isToggled?: boolean;
  onToggle?: () => void;
}

const IconColor = {
  default: 'text-mountain-mist',
  disabled: 'text-mercury',
  error: 'text-coral-pink'
};

export const TextFieldIcon = ({
  atEnd = false,
  disabled = false,
  error,
  icon,
  isToggled = false,
  onToggle
}: TextFieldIconProps) => {
  const iconColor = (disabled && 'disabled') || (error && 'error') || 'default';

  const handleToggle = useCallback(() => {
    onToggle?.();
  }, [onToggle]);

  return (
    <Icon
      classes={clsx('absolute inset-y-0 w-9 flex justify-center items-center cursor-pointer', {
        'right-0': atEnd
      })}
      name={icon}
      color={clsx(IconColor[iconColor], { 'text-opacity-40': !isToggled })}
      onClick={handleToggle}
    />
  );
};
