import { useEffect, useState } from 'react';

import { useIntersectionObserver } from './use-intersection-observer';

export const useIsElementShown = () => {
  const [isShown, setIsShown] = useState(false);
  const [elementContainer, setElementContainer] = useState<HTMLDivElement | null>(null);

  const entry = useIntersectionObserver(elementContainer, {
    freezeOnceVisible: false,
    threshold: 0.05
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && !isShown) {
      setIsShown(true);
    }
  }, [isShown, isVisible]);

  return { isShown, ref: setElementContainer };
};
