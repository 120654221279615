import { ReactNode } from 'react';
import clsx from 'clsx';

import { Loader, TLoaderVariant } from '../loading/components/loader';

interface LoadingLayoutProps {
  children?: ReactNode;
  classes?: string;
  loader?: TLoaderVariant;
  loading: boolean;
  overlay?: boolean;
}

export const LoadingLayout = ({
  children,
  classes,
  loader = 'stack',
  loading,
  overlay = false
}: LoadingLayoutProps) => (
  <div className={clsx('relative', { 'w-full h-full': overlay }, classes)}>
    {loading && (
      <div
        className={clsx('absolute inset-0 flex justify-center items-center', {
          'bg-black/80 z-100': overlay
        })}
      >
        <Loader variant={loader} />
      </div>
    )}

    {children}
  </div>
);
