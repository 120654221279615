import clsx from 'clsx';

import { TLinearGradients } from '@/shared/types';
import { LinearGradient } from '@/components/common/linear-gradient';

interface LayoutStackIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const LayoutStackIcon = ({ color, gradient }: LayoutStackIconProps) => (
  <svg
    className={clsx(color)}
    width="28"
    height="33"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.8"
      width="16.0257"
      height="16.0257"
      rx="2"
      transform="matrix(0.866287 0.499547 -0.866287 0.499547 14.1172 9.01001)"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />
    <rect
      opacity="0.5"
      width="16.0257"
      height="16.0257"
      rx="2"
      transform="matrix(0.866287 0.499547 -0.866287 0.499547 14.1172 16.9883)"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />
    <rect
      width="16.0257"
      height="16.0257"
      rx="2"
      transform="matrix(0.866166 0.499757 -0.866166 0.499757 14.1172 0.97998)"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default LayoutStackIcon;
