import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { usePortalElement } from '@/shared/hooks/use-portal-element';

interface ModalPortalWrapperProps {
  rootId?: string;
  children: ReactNode;
}

export const ModalPortalWrapper = ({ rootId, children }: ModalPortalWrapperProps) => {
  const portalEl = usePortalElement(rootId);

  return portalEl ? createPortal(children, portalEl) : null;
};
