import { HomePageInstructions } from './instructions';
import { THomeInstruction } from '@/pages/home-page/state/types';
import { HomePageAboutWorkflowInfo } from './home-page-about-workflow-info';

import arrowUpIcon from '@/assets/images/arrow-up-3d.png';
import brushIcon from '@/assets/images/brush-3d.png';
import buttonIcon from '@/assets/images/button-3d.png';
import plusIcon from '@/assets/images/plus-3d.png';
import usersGroupIcon from '@/assets/images/users-group-3d.png';
import startIcon from '@/assets/images/star-green-3d.png';
import stackIcon from '@/assets/images/stack-3d.png';

const data: {
  about: string;
  instructions: THomeInstruction[];
  title: string;
} = {
  about:
    // 'Tanglius is about leveling the playing field for individual creators, businesses and brands to design, tell and monetize their own narratives. Powerful technologies can fill these gaps, yet they pose multiple challenges: complex, expensive, lack of integration... ultimately causing big dependencies on closed groups of organizations and/or individuals. Tanglius democratizes their access to everyone through easy to use and no code solutions.',
    'Tanglius empowers users to effortlessly create powerful immersive 2D/3D websites and mint them as NFTs. Secure your creations with blockchain. Monetize like never before! Foster collaborative efforts among artists, communities, and brands. All without the need for coding skills.',
  instructions: [
    {
      text: 'Access, explore a market of stories and virtual places',
      icon: buttonIcon
    },
    { text: 'Mint your capsule profile. Showcase your brand identity', icon: plusIcon },
    {
      text: 'Create and connect amazing content to other capsules, other stories',
      icon: brushIcon
    },
    {
      text: 'Publish capsules to the blockchain, share & sell stories, all run by smart contracts',
      icon: arrowUpIcon
    },
    {
      text: 'Configure your capsules as exclusive or inclusive as you want',
      icon: stackIcon
    },
    {
      text: 'Gamify and unlock special edition capsules based on community engagement and asset ownership',
      icon: usersGroupIcon
    },
    {
      text: 'Turn immersive websites as capsules into art with NFTs. Capitalize on your creativity.',
      icon: brushIcon
    },
    {
      text: 'Participate and enjoy virtual/real-world experiences & assets from your favorite brands/creators',
      icon: startIcon
    }
  ],
  title: 'How It Works'
};

export const HomePageAboutWorkflow = () => {
  const { about, instructions, ...infoProps } = data;

  return (
    <>
      <HomePageAboutWorkflowInfo {...infoProps} />

      <HomePageInstructions about={about} instructions={instructions} />
    </>
  );
};
