import { Icon } from '../common/icon';

interface ListPickerShadowProps {
  isDownEnable?: boolean;
  isUpEnable?: boolean;
  itemHeight: number;
}

export const ListPickerShadow = ({
  isDownEnable,
  isUpEnable,
  itemHeight
}: ListPickerShadowProps) => (
  <>
    <div
      className="absolute top-0 inset-x-0 w-full flex justify-center items-start bg-gradient-to-b from-rangoon-green to-transparent pointer-events-none p-1.5"
      style={{ height: itemHeight }}
    >
      {isUpEnable && <Icon name="chevron-up" color="text-june-bud" />}
    </div>

    <div
      className="absolute bottom-0 inset-x-0 w-full flex justify-center items-end bg-gradient-to-b from-transparent to-rangoon-green pointer-events-none p-1.5"
      style={{ height: itemHeight }}
    >
      {isDownEnable && <Icon name="chevron-down" color="text-june-bud" />}
    </div>
  </>
);
