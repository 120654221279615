import { useRef, useState } from 'react';
import clsx from 'clsx';

import { Icon } from '@/components/common/icon';
import { useClickOutside } from '@/shared/hooks/use-click-outside';
import { useDisableOverflowRescaling } from '@/shared/hooks/use-disable-overflow-rescaling';
import { useOpen } from '@/shared/hooks/use-open';
import { useWindowSize } from '@/shared/hooks/use-window-size';
import { LANDING_BAR_HEIGHT, LANDING_BAR_HEIGHT_MOBILE } from '../../state/constants';
import { THeaderIcon } from '../../state/types';
import { LandingLayoutSidebarMenu } from './landing-layout-sidebar-menu';

export const LandingLayoutSidebar = () => {
  const menu = useOpen();
  const ref = useRef(null);

  const { width } = useWindowSize();
  const isLayoutCompact = width < 640;

  const [isIconHovered, setIsIconHovered] = useState(false);
  const iconProps: THeaderIcon = {
    ...(isIconHovered ? { color: 'text-mantis' } : { gradient: 'juneBudConifer' })
  };

  const { onOverflow, onOverflowReset } = useDisableOverflowRescaling();

  const handleMenuToggle = () => {
    onOverflow(!menu.isOpened);

    menu.toggle();
  };

  const handleMenuClose = () => {
    if (!menu.isOpened) return;

    menu.close();

    onOverflowReset();
  };

  useClickOutside(ref, handleMenuClose);

  return (
    <aside
      ref={ref}
      className={clsx(
        'fixed inset-y-0 left-0 transition-all ease-out duration-300 z-max',
        menu.isOpened
          ? 'bg-night w-full sm:w-1/2 lg:w-1/3 shadow-drop overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-liver scrollbar-track-thunder'
          : 'w-0'
      )}
    >
      <div
        className="flex items-center px-4 sm:px-7.75"
        style={{ height: isLayoutCompact ? LANDING_BAR_HEIGHT_MOBILE : LANDING_BAR_HEIGHT }}
      >
        <Icon
          classes="cursor-pointer"
          name={menu.isOpened ? 'crossBold' : 'barsTopRight'}
          onClick={handleMenuToggle}
          onMouseEnter={() => setIsIconHovered(true)}
          onMouseLeave={() => setIsIconHovered(false)}
          {...iconProps}
        />
      </div>

      <LandingLayoutSidebarMenu isOpened={menu.isOpened} onClose={handleMenuClose} />
    </aside>
  );
};
