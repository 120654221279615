import clsx from 'clsx';

import { TLinearGradients } from '@/shared/types';
import { LinearGradient } from '@/components/common/linear-gradient';

interface ChevronDownDoubleIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const ChevronDownDoubleIcon = ({ color, gradient }: ChevronDownDoubleIconProps) => (
  <svg
    className={clsx(color)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
      stroke={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default ChevronDownDoubleIcon;
