export enum HomeModes {
  create = 'create',
  experience = 'experience'
}

export enum HomeInitialDirection {
  create = 'build',
  experience = 'explore'
}

const HomeInitialAbility = 'capsule';

const HomeInitialCapability = 'creator';

export { HomeInitialAbility, HomeInitialCapability };
