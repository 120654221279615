import { useState } from 'react';

import { Icon } from '@/components/common/icon';
import { Link } from '@/components/common/link';
import { TGradientIcon } from '@/shared/types';

interface HomePageScrollIconProps {
  scrollTo?: string;
}

export const HomePageScrollIcon = ({ scrollTo = '' }: HomePageScrollIconProps) => {
  const [isIconHovered, setIsIconHovered] = useState(false);

  const iconProps: TGradientIcon = {
    ...(isIconHovered ? { color: 'text-mantis' } : { gradient: 'juneBudConifer' })
  };

  return (
    <Link
      classes="absolute bottom-0 right-1/2 p-1.5 sm:p-2.5 animate-leap"
      to={`/#${scrollTo}`}
      noUnderline
    >
      <Icon
        classes="transition-all ease-out duration-300 cursor-pointer"
        name="chevronDownDouble"
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
        {...iconProps}
      />
    </Link>
  );
};
