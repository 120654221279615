import { ReactNode, Ref } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, To } from 'react-router-dom';
import clsx from 'clsx';

import { Anchor } from './anchor';

type AnchorProps = Omit<JSX.IntrinsicElements['a'], 'children' | 'onClick'>;
type RoutingLinkProps = Omit<RouterLinkProps, 'children' | 'onClick' | 'to'>;

type LinkProps = Partial<RoutingLinkProps | AnchorProps> & {
  children: ReactNode;
  classes?: string;
  noUnderline?: boolean;
  onClick?: () => void;
  ref?: Ref<HTMLAnchorElement> | undefined;
  to?: To | undefined;
  variant?: keyof typeof LinkVariant;
};

const LinkVariant = {
  default: 'text-base text-left text-warm-grey font-medium',
  primary: 'text-3xl text-left text-white leading-17 font-outfit font-normal tracking-tighter',
  secondary: 'text-xl text-left text-white leading-15 font-mono font-normal tracking-tighter'
};

export const Link = ({
  classes,
  noUnderline = false,
  to,
  variant = 'default',
  ...props
}: LinkProps) => {
  const linkClasses = clsx(
    LinkVariant[variant],
    {
      'no-underline hover:underline': !noUnderline
    },
    classes
  );

  if (to) {
    return <RouterLink className={linkClasses} to={to} {...props} />;
  }

  return <Anchor classes={linkClasses} {...props} />;
};
