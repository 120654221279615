export enum IconNames {
  barsTopRight = 'bars-top-right-icon',
  chevronDownDouble = 'chevron-down-double-icon',
  'chevron-down' = 'chevron-down-icon',
  'chevron-up' = 'chevron-up-icon',
  crossBold = 'cross-bold-icon',
  discordLarge = 'discord-large-icon',
  eye = 'eye-icon',
  instagramLarge = 'instagram-large-icon',
  landingBrandLogo = 'landing-brand-logo-icon',
  layoutStack = 'layout-stack-icon',
  letter = 'letter-icon',
  spingLoader = 'spin-loader-icon',
  stackLoader = 'stack-loader-icon',
  twitterLarge = 'twitter-large-icon'
}

export enum LinearGradients {
  juneBudConifer = 'svg-june-bud-conifer-gradient'
}
