import { useCallback, useState } from 'react';
import clsx from 'clsx';

import { ListPicker, TListPickerItem } from '@/components/list-picker';
import { PickerDirection } from '@/components/list-picker/state/constants';
import { THomeInstruction } from '@/pages/home-page/state/types';
import { useIsElementShown } from '@/shared/hooks/use-is-element-shown';

interface HomePageInsctructionsListProps {
  items: THomeInstruction[];
}

export const HomePageInsctructionsList = ({ items }: HomePageInsctructionsListProps) => {
  const { isShown, ref } = useIsElementShown();
  const [value, setValue] = useState<TListPickerItem>({ text: '', icon: '' });

  const handleChangeDirection = useCallback((direction: TListPickerItem) => {
    setValue(direction);
  }, []);

  return (
    <div
      className={clsx(
        'flex flex-col gap-2.5 will-change-transform',
        isShown
          ? 'translate-x-0 opacity-100 transition-all ease-in duration-700 delay-300'
          : 'translate-x-36 lg:translate-x-1/2 opacity-0'
      )}
      ref={ref}
    >
      <ListPicker
        id="instructions-picker"
        isDownEnable
        isUnidirectional
        direction={PickerDirection.DOWN}
        items={items}
        onChange={handleChangeDirection}
        value={value}
      />
    </div>
  );
};
