import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@/components/icon-button';
import Route from '@/config/routes/types';
import { useWindowSize } from '@/shared/hooks/use-window-size';
import { TLinearGradients } from '@/shared/types';
import { LANDING_BAR_HEIGHT, LANDING_BAR_HEIGHT_MOBILE } from '../../state/constants';

export const LandingLayoutHeaderExplore = () => {
  const navigate = useNavigate();
  const [isIconHovered, setIsIconHovered] = useState(false);

  const { width } = useWindowSize();
  const isLayoutCompact = width < 640;

  const iconProps: { gradient?: TLinearGradients } = {
    ...(!isIconHovered && { gradient: 'juneBudConifer' })
  };

  return (
    <div
      className="w-screen fixed inset-x-0 flex justify-end items-center z-max pointer-events-none"
      style={{ height: isLayoutCompact ? LANDING_BAR_HEIGHT_MOBILE : LANDING_BAR_HEIGHT }}
    >
      <IconButton
        atEnd
        classes="w-fit hover:text-mantis px-4 sm:px-7.75 pointer-events-auto"
        color="gradient"
        icon="layoutStack"
        onClick={() => navigate(Route.CONTACT_US)}
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
        size="inner"
        spacing="md"
        text="Explore"
        {...iconProps}
      />
    </div>
  );
};
