import { InputProps } from '../common/input';
import { TextFieldHelper } from './text-field-helper';
import { TextFieldIcon } from './text-field-icon';
import { TextFieldLabeled } from './text-field-labeled';

interface TextFieldProps extends InputProps {
  isToggled?: boolean;
  label?: string;
  onToggle?: () => void;
}

export const TextField = ({
  error,
  isToggled,
  helperText,
  onToggle,
  placeholder = ' ',
  ...inputProps
}: TextFieldProps) => (
  <div className="z-0">
    <div className="relative">
      {inputProps.icon && (
        <TextFieldIcon
          atEnd={inputProps.atEnd}
          disabled={inputProps.disabled}
          error={error}
          icon={inputProps.icon}
          isToggled={isToggled}
          onToggle={onToggle}
        />
      )}

      <TextFieldLabeled error={error} {...inputProps} />
    </div>

    {error && helperText && <TextFieldHelper text={helperText} />}
  </div>
);
