import { Outlet } from 'react-router-dom';

import { LandingLayoutHeader } from './components/header';
import { LandingLayoutMain } from './components/landing-layout-main';
import { LandingLayoutSidebar } from './components/sidebar';
import { LandingLayoutFooter } from './components/footer';

export const LandingLayout = () => (
  <>
    <LandingLayoutHeader />

    <LandingLayoutMain>
      <Outlet />
    </LandingLayoutMain>

    <LandingLayoutSidebar />

    <LandingLayoutFooter />
  </>
);
