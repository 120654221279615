import { Route, Routes } from 'react-router-dom';

import { LayoutWrapper } from './components/layout/layout-wrapper';
import { renderRoutes } from './shared/utils/routes';
import mainRoutes from './config/routes/main-routes';

const App = () => (
  <Routes>
    <Route path="/" element={<LayoutWrapper />}>
      {renderRoutes(mainRoutes)}
    </Route>
  </Routes>
);

export default App;
