import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Route from '@/config/routes/types';
import { useContactUsMutation } from '@/redux/services/contact';
import { isEmail } from '@/shared/utils/string';

export const useContactModalData = () => {
  const navigate = useNavigate();

  const setContactModalClosed = useCallback(() => {
    navigate(Route.ROOT);
  }, [navigate]);

  return {
    setModalClosed: setContactModalClosed
  };
};

export const useContactData = () => {
  const { setModalClosed } = useContactModalData();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState({ name: '', email: '', message: '' });

  const [contact, { data, isLoading, isError, isSuccess, isUninitialized, reset }] =
    useContactUsMutation();

  const setContactName = useCallback(
    (name: string) => {
      setName(name);

      if (error) {
        setError((errors) => ({ ...errors, name: '' }));
      }
    },
    [error]
  );

  const setContactEmail = useCallback(
    (email: string) => {
      setEmail(email);

      if (error) {
        setError((errors) => ({ ...errors, email: '' }));
      }
    },
    [error]
  );

  const setContactMessage = useCallback(
    (message: string) => {
      setMessage(message);

      if (error) {
        setError((errors) => ({ ...errors, message: '' }));
      }
    },
    [error]
  );

  const resetContactForm = useCallback(() => {
    setName('');
    setEmail('');
    setMessage('');
  }, []);

  const handleContact = useCallback(() => {
    let errors = error;

    if (!name) {
      errors = { ...errors, name: 'Empty name' };
    }

    if (!isEmail(email)) {
      errors = { ...errors, email: 'Invalid email' };
    }

    if (!message) {
      errors = { ...errors, message: 'Empty message' };
    }

    if (Object.values(errors).some((error) => error)) {
      setError(errors);
      return;
    }

    contact({ name, email, message });
  }, [contact, email, error, message, name]);

  useEffect(() => {
    if (isUninitialized) return;

    if (isSuccess) {
      setModalClosed();

      toast.success(data);
    }

    if (isError || isSuccess) {
      reset();
    }

    resetContactForm();
  }, [data, isError, isSuccess, isUninitialized, reset, resetContactForm, setModalClosed]);

  return {
    name,
    email,
    message,
    error,
    isLoading,

    setName: setContactName,
    setEmail: setContactEmail,
    setMessage: setContactMessage,
    contact: handleContact
  };
};
