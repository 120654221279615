import { Suspense, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

import { ThreeDimensionalPickerAnnotation } from './state/types';
import { ThreeDimensionalAnnotation } from './three-dimensional-annotation';
import {
  ThreeDimensionalPickerModel,
  ThreeDimensionalPickerModelProps
} from './three-dimensional-picker-model';

interface ThreeDimensionalPickerProps extends ThreeDimensionalPickerModelProps {
  annotations: ThreeDimensionalPickerAnnotation[];
  value: ThreeDimensionalPickerAnnotation['key'] | undefined;
  onPickValue: (annotation: ThreeDimensionalPickerAnnotation) => void;
}

export const ThreeDimensionalPicker = ({
  annotations,
  color,
  onPickValue,
  variant,
  value
}: ThreeDimensionalPickerProps) => {
  const handlePickAnnotation = useCallback(
    (annotation: ThreeDimensionalPickerAnnotation) => {
      onPickValue(annotation);
    },
    [onPickValue]
  );

  return (
    <Canvas
      className="w-full h-full cursor-move !overflow-visible"
      dpr={[1, 2]}
      frameloop="demand"
      shadows
    >
      <ambientLight intensity={0.1} />

      <PerspectiveCamera makeDefault position={[0, 7.5, 7]} fov={25}>
        <directionalLight
          // castShadow
          color="#ffffff"
          intensity={0.6}
          position={[-3.3, 1.7, 1.1]}
          shadow-mapSize={[512, 512]}
        />
      </PerspectiveCamera>

      <Suspense fallback={null}>
        <ThreeDimensionalPickerModel color={color} variant={variant} />

        {annotations.map(({ key, ...annotationProps }, idx) => (
          <ThreeDimensionalAnnotation
            color={color}
            isActive={key === value}
            key={idx}
            onClick={() => handlePickAnnotation(annotations[idx])}
            {...annotationProps}
          />
        ))}

        <OrbitControls makeDefault enableZoom={false} enablePan={false} />
      </Suspense>
    </Canvas>
  );
};
