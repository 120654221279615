import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface LetterIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const LetterIcon = ({ color, gradient }: LetterIconProps) => (
  <svg
    className={clsx(color)}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.125 10.9687C5.125 9.86418 6.02043 8.96875 7.125 8.96875H33.875C34.9796 8.96875 35.875 9.86418 35.875 10.9688V30.75C35.875 31.0898 35.74 31.4157 35.4997 31.656C35.2595 31.8963 34.9336 32.0312 34.5938 32.0312H6.40625C6.06644 32.0312 5.74055 31.8963 5.50027 31.656C5.25999 31.4157 5.125 31.0898 5.125 30.75V10.9687Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={gradient ? `url(#${gradient})` : 'none'}
    />
    <path
      d="M34.5938 9.60938L21.881 21.7443C21.1081 22.482 19.8919 22.482 19.119 21.7443L6.40625 9.60938"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6973 20.5L5.52539 31.6629"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4746 31.6629L23.3027 20.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default LetterIcon;
