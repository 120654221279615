import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const middlewareErrorToast: Middleware = () => (next) => (action) => {
  let errorMessage = '';

  if (isRejectedWithValue(action)) {
    errorMessage = action.payload.data || 'Something went wrong';
  }

  if (errorMessage) {
    toast.error(errorMessage);
  }

  return next(action);
};
