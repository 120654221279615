import { THomeInstruction } from '@/pages/home-page/state/types';
import { HomePageInsctructionsList } from './home-page-insctructions-list';
import { HomePageInstructionsAbout } from './home-page-instructions-about';

interface HomePageInstructionsProps {
  about: string;
  instructions: THomeInstruction[];
}

export const HomePageInstructions = ({ about, instructions }: HomePageInstructionsProps) => (
  <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center gap-3 lg:gap-14 xl:gap-20">
    <HomePageInstructionsAbout description={about} />

    <HomePageInsctructionsList items={instructions} />
  </div>
);
