import { createElement, CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

const ContainerSizes = {
  none: 'px-0',
  xs: 'px-4',
  sm: 'px-6',
  md: 'px-7',
  'semi-lg': 'px-7.75',
  lg: 'px-8'
};

const SectionSizes = {
  none: 'py-0',
  xs: 'py-4',
  sm: 'py-6',
  md: 'py-7',
  'semi-lg': 'py-7.75',
  lg: 'py-8'
};

type ContainerComponents = 'div' | 'header' | 'main' | 'footer';

interface ContainerProps {
  as?: ContainerComponents;
  children: ReactNode;
  classes?: string;
  id?: string;
  size?: keyof typeof ContainerSizes;
  style?: CSSProperties;
  withSection?: boolean;
}

export const Container = ({
  as = 'div',
  children,
  classes,
  size = 'none',
  withSection = false,
  ...props
}: ContainerProps) =>
  createElement(
    as,
    {
      className: clsx(ContainerSizes[size], { [SectionSizes[size]]: withSection }, classes),
      ...props
    },
    children
  );
