import { RootState } from '@/redux/types';
import { HomeState } from './slice';

export const getMode = (state: RootState): HomeState['mode'] => state.home.mode;

export const getAbility = (state: RootState): HomeState['ability'] => state.home.ability;

export const getDirection = (state: RootState): HomeState['direction'] => state.home.direction;

export const getCapability = (state: RootState): HomeState['capability'] => state.home.capability;

export const getSectionsInfo = (state: RootState): HomeState['sectionsInfo'] =>
  state.home.sectionsInfo;
