interface TextFieldHelperProps {
  text: string;
}

export const TextFieldHelper = ({ text }: TextFieldHelperProps) => (
  <p
    className="text-semi-xs text-left text-coral-pink font-mono font-normal tracking-dense"
    id="error-helper"
  >
    {text}
  </p>
);
