import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface InstagramLargeIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const InstagramLargeIcon = ({ color, gradient }: InstagramLargeIconProps) => (
  <svg
    className={clsx(color)}
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1265 5.20312H12.4077C8.57624 5.20312 5.47021 8.30915 5.47021 12.1406V24.8594C5.47021 28.6909 8.57624 31.7969 12.4077 31.7969H25.1265C28.9579 31.7969 32.064 28.6909 32.064 24.8594V12.1406C32.064 8.30915 28.9579 5.20312 25.1265 5.20312Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={gradient ? `url(#${gradient})` : 'none'}
    />

    <path
      d="M18.7671 24.2812C21.96 24.2812 24.5483 21.6929 24.5483 18.5C24.5483 15.3071 21.96 12.7188 18.7671 12.7188C15.5742 12.7188 12.9858 15.3071 12.9858 18.5C12.9858 21.6929 15.5742 24.2812 18.7671 24.2812Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />

    <path
      d="M27.9546 10.4062C27.9546 11.0103 27.4649 11.5 26.8608 11.5C26.2568 11.5 25.7671 11.0103 25.7671 10.4062C25.7671 9.80219 26.2568 9.3125 26.8608 9.3125C27.4649 9.3125 27.9546 9.80219 27.9546 10.4062Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.125"
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default InstagramLargeIcon;
