import { Fragment, useEffect } from 'react';

import { useDisableOverflowRescaling } from '@/shared/hooks/use-disable-overflow-rescaling';
import { ModalFrame, ModalFrameProps } from './modal-frame';
import { ModalPortalWrapper } from './modal-portal-wrapper';

interface EnhancedModalFrameProps extends ModalFrameProps {
  id?: string;
  portal?: boolean;
}

export const EnhancedModalFrame = ({
  id,
  open: isOpen,
  portal: isPortal,

  ...modalProps
}: EnhancedModalFrameProps) => {
  const rootId = id || 'modal-portal';

  const WrapperComponent = isPortal ? ModalPortalWrapper : Fragment;
  const wrapperProps = {
    ...(isPortal && { rootId })
  };

  const { onOverflow, onOverflowReset } = useDisableOverflowRescaling();

  useEffect(() => {
    onOverflow(isOpen);

    return () => {
      onOverflowReset();
    };
  }, [isOpen, onOverflow, onOverflowReset]);

  return isOpen ? (
    <WrapperComponent {...wrapperProps}>
      <ModalFrame open={isOpen} {...modalProps} />
    </WrapperComponent>
  ) : null;
};
