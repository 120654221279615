import { ReactNode } from 'react';
import clsx from 'clsx';

import { THomePageSectionProps } from '../state/types';
import { HomePageScrollIcon } from './home-page-scroll-icon';
import { useIsElementShown } from '@/shared/hooks/use-is-element-shown';

interface HomePageSectionProps extends THomePageSectionProps {
  children: ReactNode;
  classes?: string;
}

export const HomePageSection = ({
  children,
  classes,
  id,
  scrollTo,
  wrapperClasses
}: HomePageSectionProps) => {
  const { isShown, ref } = useIsElementShown();

  return (
    <div
      id={id}
      className={clsx(
        'relative w-full min-h-screen justify-center bg-smoky-black overflow-hidden',
        isShown ? 'opacity-100 transition-all ease-in duration-1000' : 'opacity-0',
        wrapperClasses
      )}
    >
      <div className={clsx('w-full', classes)} ref={ref}>
        {children}

        {scrollTo && <HomePageScrollIcon scrollTo={scrollTo} />}
      </div>
    </div>
  );
};
