import { HTMLAttributes } from 'react';

import { TGradientColor, TIconNames, TLinearGradients } from '@/shared/types';
import { IconNames } from '@/shared/state/constants';

export interface IconProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'color'> {
  classes?: string;
  color?: TGradientColor | string;
  gradient?: TLinearGradients;
  name?: TIconNames;
}

export const Icon = ({ classes, color, gradient, name = 'eye', ...iconProps }: IconProps) => {
  const IconPath = require(`@/shared/components/icons/${IconNames[name]}`).default;

  return (
    <span className={classes} {...iconProps}>
      <IconPath color={color} gradient={gradient} />
    </span>
  );
};
