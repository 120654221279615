import { useGLTF } from '@react-three/drei';
import { FrontSide } from 'three';

import { ThreeDimensionalPickerColors } from './state/types';
import { PickerColors } from './state/constants';

export interface ThreeDimensionalPickerModelProps {
  color?: ThreeDimensionalPickerColors;
  variant?: keyof typeof ModelVariant;
}

const ModelMaterialType = {
  primary: {
    [PickerColors.green]: { emissive: '#baff00', emissiveIntensity: 0.2, color: '#f6ff00' },
    [PickerColors.orange]: { emissive: '#ff4000', emissiveIntensity: 0.2, color: '#f6d737' }
  },
  secondary: {
    [PickerColors.green]: { emissive: '#daff76', emissiveIntensity: 0.3, color: 'white' },
    [PickerColors.orange]: {
      emissive: '#ff9875',
      emissiveIntensity: 0.3,
      color: 'white'
    }
  }
};

const ModelVariant = {
  cube: {
    model: 'cube.gltf',
    meshes: [
      {
        name: 'Cube003_Material004',
        material: ModelMaterialType.primary
      },
      {
        name: 'Cube004_Material005',
        material: ModelMaterialType.secondary
      },
      {
        name: 'Cube005_Material004',
        material: ModelMaterialType.primary
      }
    ]
  },
  sphere: {
    model: 'sphere.glb',
    meshes: [
      {
        name: 'Icosphere001_Icosphere001_Material001',
        material: ModelMaterialType.primary
      }
    ]
  }
};

export const ThreeDimensionalPickerModel = ({
  color = 'green',
  variant = 'cube'
}: ThreeDimensionalPickerModelProps) => {
  const { nodes }: any = useGLTF(`/models/${ModelVariant[variant].model}`);

  return (
    <group dispose={null} position={[-0.1, -0.05, 0]} rotation={[0, 1.5, -0.54]}>
      {ModelVariant[variant].meshes.map((meshProps, idx) => (
        <mesh castShadow geometry={(nodes[meshProps.name] as any).geometry} key={idx} receiveShadow>
          <meshStandardMaterial
            color={meshProps.material[color].color}
            emissive={meshProps.material[color].emissive}
            emissiveIntensity={meshProps.material[color].emissiveIntensity}
            fog
            metalness={0}
            roughness={1}
            side={FrontSide}
          />
        </mesh>
      ))}
    </group>
  );
};

useGLTF.preload('/models/cube.gltf');
useGLTF.preload('/models/sphere.glb');
