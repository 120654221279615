import { useCallback } from 'react';

import {
  ThreeDimensionalPicker,
  ThreeDimensionalPickerAnnotation
} from '@/components/three-dimensional-picker';
import { HomeInitialCapability } from '@/pages/home-page/state/constants';

interface HomePageCapabilitiesPickerProps {
  items: ThreeDimensionalPickerAnnotation[];
  onChange: (directionKey: string) => void;
  value: string;
}

export const HomePageCapabilitiesPicker = ({
  items,
  onChange,
  value = HomeInitialCapability
}: HomePageCapabilitiesPickerProps) => {
  const handlePickCapability = useCallback(
    ({ key }: ThreeDimensionalPickerAnnotation) => onChange(key),
    [onChange]
  );

  return (
    <div className="w-4/5 sm:w-2/3 lg:w-10/12 xl:w-4/5 2xl:w-3/5 h-auto justify-self-center aspect-11/12 lg:aspect-1000/1083">
      <ThreeDimensionalPicker
        annotations={items}
        variant="sphere"
        value={value}
        onPickValue={handlePickCapability}
      />
    </div>
  );
};
