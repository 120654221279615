import clsx from 'clsx';

import { TIconNames } from '@/shared/types';
import { Icon, IconProps } from './common/icon';

interface IconButtonProps extends IconProps {
  atEnd?: boolean;
  classes?: string;
  color?: TIconButtonColor;
  glassy?: boolean;
  icon?: TIconNames;
  onClick?: () => void;
  size?: keyof typeof IconButtonSize;
  spacing?: keyof typeof IconButtonSpacing;
  text?: string;
  variant?: keyof typeof IconButtonVariant;
}

export type TIconButtonColor = keyof typeof IconColor;

const IconColor = {
  default: 'text-zeus hover:text-mantis',
  success: 'text-white',
  disabled: 'text-mercury',
  transparent: 'text-inherit',
  light: 'text-inherit',
  gradient: 'text-transparent bg-clip-text bg-june-bud-conifer'
};

const ButtonColor = {
  default: 'bg-white-lilac',
  success: 'bg-mantis',
  disabled: 'bg-slate-100',
  transparent: 'bg-inherit',
  light: 'bg-white',
  gradient: 'bg-inherit'
};

const IconButtonVariant = {
  default: 'rounded-full',
  rounded: 'rounded shadow-header',
  plain: 'w-auto h-full aspect-square'
};

const IconButtonSize = {
  inner: 'p-0',
  xs: 'p-1',
  sm: 'p-2.5',
  md: 'p-3',
  lg: 'p-4'
};

const IconButtonSpacing = {
  sm: 'gap-3',
  md: 'gap-4'
};

export const IconButton = ({
  atEnd = false,
  classes,
  color = 'default',
  glassy = false,
  gradient,
  icon,
  onClick,
  onMouseEnter,
  onMouseLeave,
  size = 'sm',
  spacing = 'sm',
  text,
  variant = 'default'
}: IconButtonProps) => (
  <div
    className={clsx(
      'flex items-center cursor-pointer',
      { 'bg-opacity-10 backdrop-blur-semi-md hover:bg-slate-900': glassy },
      { 'aspect-square': icon && !text },
      ButtonColor[color],
      IconButtonSize[size],
      IconButtonSpacing[spacing],
      IconButtonVariant[variant],
      IconColor[color],
      classes
    )}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {icon && (
      <Icon
        classes={clsx('w-full h-full flex justify-center items-center', { 'order-last': atEnd })}
        name={icon}
        gradient={gradient}
      />
    )}

    {text && (
      <span className="text-sm text-left font-mono font-normal -tracking-semi-tight">{text}</span>
    )}
  </div>
);
