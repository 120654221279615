import { useEffect } from 'react';

import { useHomeData } from '@/pages/home-page/state/hooks';
import { THomeCapability } from '@/pages/home-page/state/types';
import { HomePageCapabilitiesInfo } from './home-page-capabilities-info';
import { HomePageCapabilitiesPicker } from './home-page-capabilities-picker';
import { HomeInitialCapability } from '@/pages/home-page/state/constants';

interface HomePageCapabilitiesProps {
  capabilities: THomeCapability[];
}

export const HomePageCapabilities = ({ capabilities }: HomePageCapabilitiesProps) => {
  const { capability, setCapability } = useHomeData();

  const currentCapability = capabilities.find(({ key }) => key === capability);

  useEffect(() => {
    if (currentCapability) return;

    setCapability(HomeInitialCapability);
  }, [currentCapability, setCapability]);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center gap-3 lg:gap-14 xl:gap-20">
      <HomePageCapabilitiesPicker
        items={capabilities}
        onChange={setCapability}
        value={capability}
      />

      <HomePageCapabilitiesInfo title={currentCapability?.description} />
    </div>
  );
};
