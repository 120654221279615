import { useCallback } from 'react';

import {
  ThreeDimensionalPicker,
  ThreeDimensionalPickerAnnotation
} from '@/components/three-dimensional-picker';
import { HomeInitialAbility } from '@/pages/home-page/state/constants';

interface HomePageAbilityPickerProps {
  items: ThreeDimensionalPickerAnnotation[];
  onChange: (abilityKey: string) => void;
  value: string | undefined;
}

export const HomePageAbilityPicker = ({
  items,
  onChange,
  value = HomeInitialAbility
}: HomePageAbilityPickerProps) => {
  const handlePickDirection = useCallback(
    ({ key }: ThreeDimensionalPickerAnnotation) => onChange(key),
    [onChange]
  );

  return (
    <div className="w-4/5 sm:w-2/3 lg:w-10/12 xl:w-4/5 2xl:w-3/5 h-auto justify-self-center aspect-11/12 lg:aspect-1000/1083">
      <ThreeDimensionalPicker
        annotations={items}
        variant="cube"
        value={value}
        onPickValue={handlePickDirection}
      />
    </div>
  );
};
