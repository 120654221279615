import { useLayoutEffect, useState } from 'react';

export const usePortalElement = (id: string = 'portal') => {
  const [portalEl, setPortalEl] = useState<HTMLDivElement | null>();

  useLayoutEffect(() => {
    if (!portalEl) {
      let element = document.getElementById(id) as HTMLDivElement;

      if (!element) {
        element = document.createElement('div');

        element.setAttribute('id', id);

        Object.assign(element.style, {
          position: 'fixed',
          inset: 0,
          zIndex: 9999999999,
          display: 'flex'
        });

        document.body.append(element);
      }

      setPortalEl(element);
    }

    return () => {
      if (portalEl) {
        document.getElementById(id)?.remove();
      }
    };
  }, [id, portalEl]);

  return portalEl;
};
