interface HomePageAboutWorkflowInfoProps {
  description?: string;
  title?: string;
}

export const HomePageAboutWorkflowInfo = ({
  description,
  title
}: HomePageAboutWorkflowInfoProps) => (
  <div className="w-full flex flex-col gap-7.75 sm:gap-12.5 py-3 items-start">
    {title && (
      <div className="text-3xl sm:text-semi-8xl text-left text-white leading-7.5 sm:leading-21 font-outfit font-normal tracking-tighter">
        {title}
      </div>
    )}

    {description && (
      <div className="text-sm sm:text-lg text-left text-white leading-6.5 sm:leading-8 font-mono font-normal tracking-dense">
        {description}
      </div>
    )}
  </div>
);
