import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface TwitterLargeIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const TwitterLargeIcon = ({ color, gradient }: TwitterLargeIconProps) => (
  <svg
    className={clsx(color)}
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4935 11.7818C18.4935 8.60209 21.1664 5.95717 24.3449 6.00053C25.4581 6.01338 26.5438 6.34757 27.4717 6.96292C28.3995 7.57828 29.1299 8.4486 29.5751 9.46928H34.6753L30.0086 14.1376C29.7074 18.8271 27.6323 23.2257 24.2051 26.4394C20.7779 29.653 16.2559 31.4403 11.5585 31.438C6.93511 31.438 5.77927 29.7036 5.77927 29.7036C5.77927 29.7036 10.4026 27.9693 12.7143 24.5005C12.7143 24.5005 3.4676 19.8755 5.77927 7.15678C5.77927 7.15678 11.5585 12.938 18.4935 14.0943V11.7818Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={gradient ? `url(#${gradient})` : 'none'}
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default TwitterLargeIcon;
