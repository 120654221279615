import clsx from 'clsx';

import { useIsElementShown } from '@/shared/hooks/use-is-element-shown';

interface HomePageAboutCapsuleInfoProps {
  description: string;
  emphasizeSubstr: string;
}

export const HomePageAboutCapsuleInfo = ({
  description,
  emphasizeSubstr
}: HomePageAboutCapsuleInfoProps) => {
  const { isShown, ref } = useIsElementShown();
  const [leftSubstr, rightSubstr] = description.split(emphasizeSubstr);

  return (
    <div
      className="text-left text-white transition-all font-mono font-normal tracking-dense py-3"
      ref={ref}
    >
      <span className="text-sm sm:text-2xl leading-6.5 sm:leading-8">{leftSubstr}</span>

      <span
        className={clsx('text-sm sm:text-2xl font-normal', { 'animate-text-enlarge': isShown })}
      >
        {emphasizeSubstr}
      </span>

      <span className="text-sm sm:text-2xl leading-6.5 sm:leading-8">{rightSubstr}</span>
    </div>
  );
};
