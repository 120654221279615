import { HomeInitialAbility } from '@/pages/home-page/state/constants';
import { THomeAbility } from '@/pages/home-page/state/types';
import { HomePageAbilities } from './abilities';
import { HomePageAboutCapsuleInfo } from './home-page-about-capsule-info';

const data: {
  abilities: THomeAbility[];
  description: string;
  emphasizeSubstr: string;
} = {
  abilities: [
    {
      distanceFactor: 4,
      key: HomeInitialAbility,
      title: 'Capsule',
      position: [0, 0, 0],
      heading: 'What are Capsules?',
      categories: [
        { text: 'NFT-based virtual building blocks' },
        {
          text: 'They can encapsulate a Brand Profile, a Catalog, a Campaign, a Place,... Everything can be capsuled'
        },
        {
          text: 'They can embed and connect to other capsules. Endless Immersive Possibilities'
        }
      ]
    },
    {
      key: 'community',
      title: 'Community',
      position: [-1.6, 1.45, -0.05],
      categories: [
        { text: 'Engage, connect and inspire a broader audience' },
        { text: 'Elevate your online presence' },
        { text: 'Foster collaboration between individuals, businesses, communities and brands' }
      ]
    },
    {
      key: 'no code',
      title: 'No Code',
      position: [0, 1.45, -1.6],
      categories: [
        { text: 'Inclusive ecosystem: empowering everyone, no technical skills required' },
        { text: 'Effortlessly craft 2D/3D websites' },
        { text: 'Unleash creativity without coding constraints' }
      ]
    },
    {
      key: 'interactive',
      title: 'Interactive',
      position: [1.5, 1.45, 0.1],
      categories: [
        { text: 'Build interactive and visually stunning capsules' },
        { text: 'Innovative bridges between digital and real world experiences' },
        { text: 'Interactive immersive assets: blogs, presentations, marketplaces, showrooms, educational material...' }
      ]
    },
    {
      key: 'Web3',
      title: 'Web3',
      position: [-1.6, -1.25, -0.05],
      categories: [
        { text: 'Public, Private, Limited editions...' },
        { text: 'Creators are in full control of their narratives' },
        { text: 'Unparalleled exclusivity and security through NFT technology' }
      ]
    },
    {
      key: 'trust',
      title: 'Trust',
      position: [0, -1.25, 1.5],
      categories: [
        { text: 'Ensure authenticity and provenance of your NFT capsules' },
        { text: 'Ownership and Scarcity: each capsule becomes a unique, ownable digital asset' },
        { text: 'Automate royalty, licensing and distribution using smart contracts' }
      ]
    },
    {
      key: 'monetization',
      title: 'Monetization',
      position: [1.5, -1.25, -0.05],
      categories: [
        { text: 'Immersive websites as NFTs can be sold in open marketplaces, creating new revenue streams' },
        { text: 'Showcase your creativity, stories and digital portfolio to attract clients, employers and collaborators' },
        { text: 'Sell assets to businesses and individuals looking for innovative digital assets' }

        // { text: 'Build your own marketplaces: campaigns, real estate, digital twins, private events...' },
        // { text: 'Tokenize collaboration and social engagement' },
        // { text: 'Digital affiliation and social ambassador programs ' }
      ]
    }
  ],
  description:
    // ' Immersive Web3 for communities to create, delight and collaborate',
    'Tanglius is an all-in-one Web3 ecosystem, seamlessly integrating a vast digital marketplace, versatile content creation and interconnected experiences enabling users to create, showcase and monetize their content. A bridge between art and technology, where imagination meets innovation.',
    // 'Tanglius is an all-in-one Web3 ecosystem to turn websites into art with NFTs, build immersive online experiences and mint them as unique digital assets on our platform. Explore a new way to showcase your creativity.',
  emphasizeSubstr: 'Web3'
};

export const HomePageAboutCapsule = () => {
  const { abilities, ...infoProps } = data;

  return (
    <>
      <HomePageAboutCapsuleInfo {...infoProps} />

      <HomePageAbilities abilities={abilities} />
    </>
  );
};
