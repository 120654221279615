import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { getAbility, getCapability, getDirection, getMode, getSectionsInfo } from './selectors';
import { setAbility, setCapability, setCurrentSectionInit, setDirection, setMode } from './slice';
import { THomeMode, THomeSection } from './types';

export const useHomeData = () => {
  const dispatch = useAppDispatch();
  const ability = useAppSelector(getAbility);
  const capability = useAppSelector(getCapability);
  const direction = useAppSelector(getDirection);
  const mode = useAppSelector(getMode);
  const sectionsInfo = useAppSelector(getSectionsInfo);

  const setHomeMode = useCallback((mode: THomeMode) => dispatch(setMode(mode)), [dispatch]);

  const setHomeAbility = useCallback(
    (ability: string) => dispatch(setAbility(ability)),
    [dispatch]
  );

  const setHomeDirection = useCallback(
    (direction: string) => dispatch(setDirection(direction)),
    [dispatch]
  );
  const setHomeCapability = useCallback(
    (capability: string) => dispatch(setCapability(capability)),
    [dispatch]
  );
  const setHomeSectionInit = useCallback(
    (section: THomeSection) => dispatch(setCurrentSectionInit(section)),
    [dispatch]
  );

  return {
    ability,
    capability,
    direction,
    mode,
    sectionsInfo,

    setAbility: setHomeAbility,
    setCapability: setHomeCapability,
    setDirection: setHomeDirection,
    setMode: setHomeMode,
    setSectionInit: setHomeSectionInit
  };
};
