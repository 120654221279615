import SpinLoaderIcon from '@/shared/components/icons/spin-loader-icon';

enum SpinLoaderSizes {
  sm = 'w-4 h-4',
  md = 'w-6 h-6',
  lg = 'w-8 h-8',
  xl = 'w-10 h-10'
}

interface SpinLoaderProps {
  size: keyof typeof SpinLoaderSizes;
}

export const SpinLoader = ({ size }: SpinLoaderProps) => (
  <SpinLoaderIcon classes={SpinLoaderSizes[size]} />
);

SpinLoader.defaultProps = {
  size: 'md'
};
