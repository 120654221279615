import { TLoaderVariant } from '../state/types';
import { SpinLoader } from './spin-loader';
import { StackLoader } from './stack-loader';

interface LoaderProps {
  variant?: TLoaderVariant;
}

export const Loader = ({ variant }: LoaderProps) => {
  let component;

  switch (variant) {
    case 'spin':
      component = <SpinLoader />;
      break;

    case 'stack':
      component = <StackLoader />;
      break;

    default:
      component = null;
      break;
  }

  return component;
};

export type { TLoaderVariant };
