import { Button } from '@/components/common/button';
import { LoadingLayout } from '@/components/layout/loading-layout';
import Modal from '@/components/modal';
import { TextField } from '@/components/text-field';
import { useSubscribeData, useSubscribeModalData } from './state/hooks';

export const SubscribePage = () => {
  const { setModalClosed } = useSubscribeModalData();
  const { email, error, isLoading, setEmail, subscribe } = useSubscribeData();

  return (
    <Modal.Frame
      classes="w-4/5 sm:w-2/3 md:w-3/5 xl:w-2/5 h-fit flex flex-col m-auto animate-slide-bottom-fast"
      closeOnClickOutside
      closeOnEsc
      color="dark"
      id="subscribe-modal"
      onClose={setModalClosed}
      open
      portal
      variant="rounded"
    >
      <LoadingLayout loading={isLoading} overlay>
        <Modal.Header size="xs" title="Subscribe" />

        <Modal.Body classes="flex flex-col gap-5 px-10" size="md">
          <div className="text-xs text-left text-mountain-mist font-mono font-normal tracking-dense">
            Coming soon... Get the latest news and launch details from Tanglius team
          </div>

          <div className="w-full">
            <TextField
              color="dark"
              error={Boolean(error)}
              helperText={error}
              id="subscribe-email"
              label="Email"
              onChange={setEmail}
              variant="outlined"
              value={email}
              size="sm"
            />
          </div>
        </Modal.Body>

        <Modal.Footer classes="flex gap-1">
          <Button classes="w-fit" size="sm" text="Cancel" onClick={setModalClosed} variant="text" />

          <Button classes="w-fit" size="sm" text="Subscribe" onClick={subscribe} />
        </Modal.Footer>
      </LoadingLayout>
    </Modal.Frame>
  );
};
