import { ReactNode } from 'react';

interface AnchorProps {
  classes?: string;
  children?: ReactNode;
  href?: string;
}

export const Anchor = ({ classes, children, ...anchorProps }: AnchorProps) => (
  <a className={classes} {...anchorProps}>
    {children}
  </a>
);
