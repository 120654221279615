import { useNavigate } from 'react-router-dom';

import Route from '@/config/routes/types';
import LandingBrandLogoIcon from '@/shared/components/icons/landing-brand-logo-icon';

export const LandingLayoutHeaderBrand = () => {
  const navigate = useNavigate();

  return (
    <div
      className="absolute top-20 sm:top-8 inset-x-0 flex justify-center cursor-pointer"
      onClick={() => navigate(Route.ROOT)}
    >
      <LandingBrandLogoIcon />
    </div>
  );
};
