import { LandingLayout } from '@/components/layout/landing-layout';
import { ContactPage } from '@/pages/contact-page';
import { HomePage } from '@/pages/home-page';
import { PrivacyPage } from '@/pages/privacy-page';
import { SubscribePage } from '@/pages/subscribe-page';
import { TermsAndConditionsPage } from '@/pages/terms-and-confitions-page';
import Route, { IRouteProps } from './types';

const mainRoutes: IRouteProps[] = [
  {
    element: HomePage,
    layout: LandingLayout,
    path: Route.ROOT,
    childrens: [
      {
        element: ContactPage,
        path: Route.CONTACT_US
      },
      {
        element: SubscribePage,
        path: Route.SUBSCRIBE
      },
      {
        element: TermsAndConditionsPage,
        path: Route.TERMS_AND_CONDITIONS
      },
      {
        element: PrivacyPage,
        path: Route.PRIVACY
      }
    ]
  }
];

export default mainRoutes;
