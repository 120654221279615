import { ReactNode } from 'react';
import clsx from 'clsx';

interface ModalBodyProps {
  children: ReactNode;
  classes?: string;
  size?: keyof typeof ModalBodySize;
}

const ModalBodySize = {
  sm: 'py-3',
  md: 'py-9'
};

export const ModalBody = ({ classes, children, size = 'sm' }: ModalBodyProps) => (
  <div className={clsx(ModalBodySize[size], classes)}>{children}</div>
);
