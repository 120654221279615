import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Route from '@/config/routes/types';
import { useSubscribeMutation } from '@/redux/services/marketing';
import { isEmail } from '@/shared/utils/string';

export const useSubscribeModalData = () => {
  const navigate = useNavigate();

  const setSubscribeModalClosed = useCallback(() => {
    navigate(Route.ROOT);
  }, [navigate]);

  return {
    setModalClosed: setSubscribeModalClosed
  };
};

export const useSubscribeData = () => {
  const { setModalClosed } = useSubscribeModalData();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [subscribe, { isError, isLoading, isSuccess, isUninitialized, reset }] =
    useSubscribeMutation();

  const setSubscribeEmail = useCallback(
    (email: string) => {
      setEmail(email);

      if (error) {
        setError('');
      }
    },
    [error]
  );

  const handleSubscribe = useCallback(() => {
    if (!isEmail(email)) {
      setError('Invalid email');

      return;
    }

    subscribe({ name: '', email, message: '' });
  }, [email, subscribe]);

  useEffect(() => {
    if (isUninitialized) return;

    if (isError) {
      setError('Something went wrong. Try again later');

      reset();
    }

    if (isSuccess) {
      setModalClosed();
    }
  }, [isError, isSuccess, isUninitialized, reset, setModalClosed]);

  return {
    email,
    error,
    isLoading,

    setEmail: setSubscribeEmail,
    subscribe: handleSubscribe
  };
};
