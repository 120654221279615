import { ReactNode } from 'react';
import clsx from 'clsx';

interface ModalHeaderProps {
  centered?: boolean;
  children?: ReactNode;
  classes?: string;
  size?: keyof typeof ModalHeaderSize;
  title: string;
}

const ModalHeaderSize = {
  xs: 'px-10 pt-7 gap-2.5',
  sm: 'pt-8 pb-4 px-4 gap-2.5',
  md: 'pt-10 pb-0.5'
};

const ModalHeaderTextSize = {
  xs: 'text-xs-2xl font-normal -tracking-semi-tight',
  sm: 'text-xs-2xl font-medium tracking-tighter',
  md: 'text-xs-4xl font-medium tracking-tighter'
};

export const ModalHeader = ({
  centered = false,
  children,
  classes,
  size = 'sm',
  title
}: ModalHeaderProps) => (
  <div className={clsx('flex', { 'justify-center': centered }, ModalHeaderSize[size])}>
    <div
      className={clsx('text-center text-inherit font-outfit', ModalHeaderTextSize[size], classes)}
    >
      {title}
    </div>

    {children && <div className="flex-1">{children}</div>}
  </div>
);
