import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry
} from '@reduxjs/toolkit/query/react';

import { SERVER_ENDPOINT } from '@/config/api';

const baseQuery = fetchBaseQuery({
  baseUrl: `${SERVER_ENDPOINT}/`
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  return result;
};

const baseQueryWithRetry = retry(baseQueryWithReAuth, { maxRetries: 1 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  reducerPath: 'baseApi'
});
