import { Icon } from '@/components/common/icon';
import { Link } from '@/components/common/link';
import { ContactIcon } from '../../state/types';

interface LandingLayoutFooterIconLinkProps extends ContactIcon {}

export const LandingLayoutFooterIconLink = ({
  icon,
  to,
  href
}: LandingLayoutFooterIconLinkProps) => {
  const linkProps = {
    icon,
    ...(to ? { to } : { href, rel: 'noopener noreferrer', target: '_blank' })
  };

  return (
    <Link classes="relative group" noUnderline {...linkProps}>
      <div className="absolute top-0 opacity-0 transition-all ease-out duration-300 group-hover:opacity-100 group-hover:translate-y-0.5 group-hover:translate-x-0.75 will-change-transform">
        <Icon color="text-transparent" name={icon} gradient="juneBudConifer" />
      </div>

      <Icon className="relative cursor-pointer z-10" color="text-white" name={icon} />
    </Link>
  );
};
