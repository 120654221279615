import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { api } from '../services/api';
import { middlewareErrorToast } from './error-toast';

const defaultMiddleware = (getDefaultMiddleWare: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleWare({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat([api.middleware, middlewareErrorToast]);

export default defaultMiddleware;
