import { useCallback, useState } from 'react';
import clsx from 'clsx';

import { VideoSlide } from './video-slide';
import { TVideoSlideSource } from './state/types';

interface VideoGalleryProps {
  classes?: string;
  videos: {
    poster: string;
    sources: TVideoSlideSource[];
    title: string;
  }[];
}

export const VideoGallery = ({ classes, videos }: VideoGalleryProps) => {
  const [currentVideoIdx, setCurrentVideoIdx] = useState(0);

  const handleVideoEnded = useCallback(() => {
    setCurrentVideoIdx((idx) => (idx + 1) % videos.length);
  }, [videos.length]);

  return (
    <div className={clsx('relative', classes)}>
      <VideoSlide mirrored onEnded={handleVideoEnded} {...videos[currentVideoIdx]} />
    </div>
  );
};
