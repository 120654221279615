import { ReactEventHandler, useState } from 'react';
import clsx from 'clsx';

import { Skeleton } from './skeleton';

type FitTypes = 'cover' | 'contain' | 'fill' | 'none';

interface ImageProps {
  alt?: string;
  classes?: string;
  fit: FitTypes;
  fitPosition?: keyof typeof ImageFitPositionClass;
  layout?: keyof typeof ImageLayout;
  src?: string;
  placeholderSrc?: string;
}

enum ImageLayout {
  fluid = 'w-full h-full',
  vertical = 'w-auto h-full'
}

enum ImageLoadSatus {
  LOADING = 'loading',
  ERROR = 'error',
  NONE = 'none'
}

enum ImageFitClass {
  cover = 'object-cover',
  contain = 'object-contain',
  fill = 'object-fill',
  none = 'object-none'
}

enum ImageFitPositionClass {
  left = 'object-left',
  right = 'object-right',
  top = 'object-top',
  bottom = 'object-bottom',
  center = 'object-center',
  'left-bottom' = 'object-left-bottom',
  'right-bottom' = 'object-right-bottom',
  'left-top' = 'object-left-top',
  'right-top' = 'object-right-top'
}

export const Image = ({
  alt,
  classes,
  fit,
  fitPosition = 'center',
  layout = 'fluid',
  src,
  placeholderSrc
}: ImageProps) => {
  const [status, setStatus] = useState(ImageLoadSatus.LOADING);

  const onLoad = () => {
    setStatus(ImageLoadSatus.NONE);
  };

  const onError: ReactEventHandler<HTMLImageElement> = ({ currentTarget }) => {
    let loadStatus = ImageLoadSatus.ERROR;

    if (placeholderSrc) {
      currentTarget.src = placeholderSrc;

      loadStatus = ImageLoadSatus.NONE;
    }

    setStatus(loadStatus);
  };

  return (
    <div className={clsx('relative', ImageLayout[layout], classes)}>
      {status === ImageLoadSatus.LOADING && (
        <div className="absolute inset-0">
          <Skeleton />
        </div>
      )}

      {status === ImageLoadSatus.ERROR ? (
        <div className={clsx('bg-thunder', ImageLayout[layout])} />
      ) : (
        <img
          src={src}
          className={clsx(
            ImageLayout[layout],
            ImageFitClass[fit],
            ImageFitPositionClass[fitPosition]
          )}
          onLoad={onLoad}
          onError={onError}
          alt={alt}
        />
      )}
    </div>
  );
};

Image.defaultProps = {
  src: '',
  placeholderSrc: '',
  fit: 'none',
  alt: 'image'
};
