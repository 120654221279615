import { HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import { TIconNames } from '@/shared/types';
import { Icon } from './icon';

export type TButtonVariant = 'default' | 'text' | 'glassy';
export type TButtonColor = 'default' | 'secondary' | 'transparent' | 'gradient' | 'primary';

export interface ButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'size' | 'type'> {
  classes?: string;
  color?: TButtonColor;
  icon?: TIconNames;
  shape?: keyof typeof ButtonShape;
  size?: keyof typeof ButtonSize;
  text: ReactNode;
  textClasses?: string;
  tooltip?: string;
  type?: 'submit' | 'button';
  variant?: TButtonVariant;
}

const ButtonShape = {
  default: 'rounded',
  pill: 'rounded-full'
};

const ButtonIconColor = {
  default: 'text-white',
  secondary: 'text-green-600',
  transparent: 'text-white hover:text-powder-ash',
  gradient: { from: '#d8d23c', to: '#a2d56a' },
  primary: 'text-white hover:text-powder-ash'
};

const ButtonDefaultColor = {
  default: 'text-white',
  secondary: 'text-mantis',
  transparent: 'text-white hover:text-powder-ash',
  gradient: 'text-transparent bg-clip-text bg-june-bud-conifer',
  primary: 'text-white hover:text-powder-ash'
};

const ButtonTextColor = {
  default: 'text-white hover:text-powder-ash',
  secondary: 'text-mantis hover:text-mantis/60',
  transparent: 'text-white hover:text-powder-ash',
  gradient: 'text-transparent bg-clip-text bg-june-bud-conifer',
  primary: 'text-white hover:text-powder-ash'
};

const ButtonGlassyColor = {
  default: 'text-white',
  secondary: 'text-mantis hover:text-kiwi-green',
  transparent: 'text-white hover:text-powder-ash',
  gradient: 'text-transparent bg-clip-text bg-june-bud-conifer',
  primary: 'text-white hover:text-powder-ash'
};

const ButtonColor = {
  default: ButtonDefaultColor,
  text: ButtonTextColor,
  glassy: ButtonGlassyColor
};

const ButtonDefaultBackground = {
  default: 'bg-kiwi-green hover:bg-mantis',
  secondary: 'bg-white hover:bg-slate-900',
  transparent: 'bg-transparent',
  gradient: 'bg-june-bud-conifer',
  primary: 'bg-transparent'
};

const ButtonTextBackground = {
  default: 'bg-transparent',
  secondary: 'bg-transparent',
  transparent: 'bg-transparent',
  gradient: 'bg-transparent',
  primary: 'bg-transparent'
};

const ButtonGlassyBackground = {
  default: 'bg-opacity-10 backdrop-blur-semi-md',
  secondary: 'bg-opacity-10 backdrop-blur-semi-md',
  transparent: 'bg-opacity-10 backdrop-blur-semi-md',
  gradient: 'bg-white/10 bg-opacity-10 backdrop-blur-semi-md hover:bg-slate-900',
  primary: 'bg-white/10 bg-opacity-10 backdrop-blur-semi-md hover:bg-slate-900'
};

const ButtonBackground = {
  default: ButtonDefaultBackground,
  text: ButtonTextBackground,
  glassy: ButtonGlassyBackground
};

const ButtonPadding = {
  sm: 'py-2.5',
  md: 'py-3.5',
  lg: 'py-4'
};

const ButtonSmallSize = {
  default: 'text-sm font-normal',
  text: 'text-sm font-normal',
  glassy: 'text-sm font-normal'
};

const ButtonMediumSize = {
  default: 'text-base font-normal',
  text: 'text-sm font-bold',
  glassy: 'text-sm font-normal'
};

const ButtonLargeSize = {
  default: 'text-base font-normal',
  text: 'text-sm font-bold',
  glassy: 'text-sm font-normal'
};

const ButtonSize = {
  sm: ButtonSmallSize,
  md: ButtonMediumSize,
  lg: ButtonLargeSize
};

export const Button = ({
  children,
  classes,
  color = 'default',
  icon,
  shape = 'default',
  size = 'md',
  text,
  textClasses,
  tooltip = '',
  variant = 'default',
  ...buttonProps
}: ButtonProps) => (
  <button
    className={clsx(
      'flex flex-nowrap justify-center items-center gap-2.5 px-5 outline-none will-change-transform transition-all duration-300',
      { 'relative inline-block group': tooltip },
      ButtonPadding[size],
      ButtonSize[size][variant],
      ButtonShape[shape],
      ButtonBackground[variant][color],
      classes
    )}
    {...buttonProps}
  >
    {icon && <Icon name={icon} color={ButtonIconColor[color]} />}

    <span
      className={clsx(
        'text-left font-mono -tracking-semi-widest whitespace-nowrap transition-colors duration-300',
        ButtonColor[variant][color],
        textClasses
      )}
    >
      {text}
    </span>

    {tooltip && (
      <span className="absolute inset-x-0 -top-2 -translate-y-full w-full hidden bg-oil rounded-md after:content-[''] after:absolute after:left-1/2 after:top-full after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-oil group-hover:flex group-hover:animate-fade-in">
        <div className="text-sm text-left text-white break-all px-3 py-2">{tooltip}</div>
      </span>
    )}

    {children}
  </button>
);
