import StackLoaderIcon from '@/shared/components/icons/stack-loader-icon';

enum StackLoaderSizes {
  sm = 'w-16 h-16',
  md = 'w-24 h-24',
  lg = 'w-32 h-32',
  xl = 'w-40 h-40'
}

interface StackLoaderProps {
  size: keyof typeof StackLoaderSizes;
}

export const StackLoader = ({ size }: StackLoaderProps) => (
  <StackLoaderIcon classes={StackLoaderSizes[size]} />
);

StackLoader.defaultProps = {
  size: 'md'
};
