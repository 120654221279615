import clsx from 'clsx';

interface ChevronDownIconProps {
  color?: string;
}

const ChevronDownIcon = ({ color }: ChevronDownIconProps) => (
  <svg
    className={clsx(color)}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

export default ChevronDownIcon;
