import { Outlet } from 'react-router-dom';

import { useScrollToContent } from '@/shared/hooks/use-scroll-to-content';
import { HomePageAboutCapsule, HomePageAboutWorkflow } from './components/about';
import { HomePageSection } from './components/home-page-section';
import { HomePageShowcase } from './components/home-page-showcase';
import { HomePageMission } from './components/mission';
import { THomePageSection } from './state/types';

export const HomePage = () => {
  const sections: THomePageSection[] = [
    {
      classes: 'h-screen aspect-9/5',
      component: HomePageShowcase,
      id: 'showcase',
      scrollTo: 'about-capsule'
    },
    {
      classes:
        'container flex flex-col items-center gap-y-3 lg:gap-y-10 xl:gap-y-18 px-4 sm:px-22 pt-10 pb-12 sm:pt-32 sm:pb-14',
      component: HomePageAboutCapsule,
      id: 'about-capsule',
      scrollTo: 'about-workflow',
      wrapperClasses: 'flex flex-col items-center'
    },
    {
      classes:
        'container flex flex-col items-center gap-y-3 lg:gap-y-10 xl:gap-y-18 px-4 sm:px-22 pt-3 pb-12 sm:pb-14',
      component: HomePageAboutWorkflow,
      id: 'about-workflow',
      scrollTo: 'use-cases',
      wrapperClasses: 'flex flex-col items-center'
    },
    {
      classes:
        'container flex flex-col items-center gap-y-3 lg:gap-y-10 xl:gap-y-18 px-4 sm:px-22 pt-3 pb-12 sm:pb-14',
      component: HomePageMission,
      id: 'use-cases',
      scrollTo: 'contact-us',
      wrapperClasses: 'flex flex-col items-center'
    }
  ];

  useScrollToContent();

  return (
    <>
      {sections.map(({ component: Component, ...sectionProps }) => (
        <HomePageSection key={sectionProps.id} {...sectionProps}>
          <Component />
        </HomePageSection>
      ))}

      <Outlet />
    </>
  );
};
