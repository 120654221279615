import { api } from './api';

export const marketingApi = api.injectEndpoints({
  endpoints: (build) => ({
    subscribe: build.mutation<any, { name: string; email: string; message: string }>({
      query: ({name, email, message}) => ({
        url: 'marketing/create',
        method: 'POST',
        body: {
          name,
          mail: email,
          message
        }
      })
    })
  })
});

export const { useSubscribeMutation } = marketingApi;
