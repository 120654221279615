import { useLayoutEffect, useState } from 'react';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;

  return { width: innerWidth, height: innerHeight };
};

export const useWindowSize = <T extends HTMLElement = HTMLDivElement>() => {
  const [{ width, height }, setDimensions] = useState(getWindowSize());

  useLayoutEffect(() => {
    const element = document.getElementById('root') as T;

    const observer = new ResizeObserver(() => {
      const rect = element?.getBoundingClientRect();
      const {
        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
        paddingLeft,
        paddingRight
      } = window.getComputedStyle(element);

      if (rect) {
        setDimensions({
          width:
            rect.width -
            parseInt(borderLeftWidth) -
            parseInt(borderRightWidth) -
            parseInt(paddingLeft) -
            parseInt(paddingRight),
          height:
            rect.height -
            parseInt(borderTopWidth) -
            parseInt(borderBottomWidth) -
            parseInt(paddingLeft) -
            parseInt(paddingRight)
        });
      }
    });

    observer.observe(element);

    return () => observer.unobserve(element);
  }, []);

  return { width, height };
};
