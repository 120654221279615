import { Route } from 'react-router-dom';
import { IRouteChildren, IRouteProps } from '@/config/routes/types';

const renderChildRoutes = (routes?: IRouteChildren[]) =>
  routes?.map(({ element: Element, path }) => (
    <Route key={path} element={<Element />} path={path} />
  ));

export const renderRoutes = (routes: IRouteProps[]) =>
  routes.map(({ element: Element, index, layout: Layout, lazy, childrens, ...routeProps }) => (
    <Route key={routeProps.path} element={<Layout />}>
      <Route element={<Element />} children={renderChildRoutes(childrens)} {...routeProps} />
    </Route>
  ));
