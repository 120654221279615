import clsx from 'clsx';
import { ReactNode } from 'react';

interface ModalFooterProps {
  children: ReactNode;
  classes?: string;
}

export const ModalFooter = ({ children, classes }: ModalFooterProps) => (
  <div className={clsx('flex justify-end border-t border-dune py-3 px-4', classes)}>{children}</div>
);
