import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { THomeMode, THomeSection } from './types';

export type HomeState = {
  mode: THomeMode;
  ability?: string;
  direction?: string;
  capability: string;
  sectionsInfo: Record<THomeSection, { initialized: boolean }>;
};

const initialState: HomeState = {
  mode: 'create',
  capability: 'marketing',
  sectionsInfo: {
    create: { initialized: false },
    experience: { initialized: false },
    direction: { initialized: false },
    mission: { initialized: false }
  }
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<THomeMode>) => {
      state.mode = action.payload;
    },
    setAbility: (state, action) => {
      state.ability = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
    setCapability: (state, action) => {
      state.capability = action.payload;
    },
    setCurrentSectionInit: (state, action: PayloadAction<THomeSection>) => {
      state.sectionsInfo = {
        ...state.sectionsInfo,
        [action.payload]: {
          ...state.sectionsInfo[action.payload],
          initialized: true
        }
      };
    }
  }
});

export const { setMode, setAbility, setDirection, setCapability, setCurrentSectionInit } =
  homeSlice.actions;
export default homeSlice.reducer;
