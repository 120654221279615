import { useEffect } from 'react';

import { HomeInitialAbility } from '@/pages/home-page/state/constants';
import { useHomeData } from '@/pages/home-page/state/hooks';
import { THomeAbility } from '@/pages/home-page/state/types';
import { HomePageAbilityInfo } from './home-page-ability-info';
import { HomePageAbilityPicker } from './home-page-ability-picker';

interface HomePageAbilitiesProps {
  abilities: THomeAbility[];
}

export const HomePageAbilities = ({ abilities }: HomePageAbilitiesProps) => {
  const { ability, setAbility } = useHomeData();

  const currentAbility = abilities.find(({ key }) => key === ability);

  useEffect(() => {
    if (currentAbility) return;

    setAbility(HomeInitialAbility);
  }, [currentAbility, setAbility]);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center gap-3 lg:gap-14 xl:gap-20">
      <HomePageAbilityPicker items={abilities} onChange={setAbility} value={ability} />

      <HomePageAbilityInfo
        categories={currentAbility?.categories}
        title={currentAbility?.heading || currentAbility?.title}
      />
    </div>
  );
};
