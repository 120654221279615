import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import rootReducer from './reducers';
import defaultMiddleware from './middleware';

export const store = configureStore({
  reducer: rootReducer,
  middleware: defaultMiddleware
});

export const persistor = persistStore(store);
