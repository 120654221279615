import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface DiscordLargeIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const DiscordLargeIcon = ({ color, gradient }: DiscordLargeIconProps) => (
  <svg
    className={clsx(color)}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 8.115 9.253 C 8.382 8.278 9.847 8.032 11.592 7.431 C 13.031 6.935 14.328 6.497 14.918 7.655 C 14.918 7.655 16.191 11.828 16.487 11.821 C 19.498 11.749 19.006 11.705 20.148 11.706 C 21.54 11.708 20.002 11.633 24.082 11.862 C 24.359 11.878 24.459 11.013 25.719 7.319 C 25.815 7.037 26.788 6.646 27.917 7.072 C 28.301 7.217 32.088 8.193 32.312 8.919 C 34.465 15.897 35.476 19.375 37.825 27.317 C 38.072 28.153 34.004 30.554 34.004 30.554 L 31.111 31.857 C 31.111 31.857 27.154 33.48 26.697 32.756 C 25.841 31.4 25.727 31.085 24.677 28.826 C 24.396 28.223 22.962 28.756 20.231 28.758 C 17.973 28.76 16.169 28.299 16.013 28.587 C 14.712 30.988 14.632 31.529 13.905 32.582 C 13.315 33.437 10.176 32.171 10.176 32.171 C 10.176 32.171 7.589 31.061 7.584 31.061 C 7.582 31.061 2.645 28.51 2.747 27.361 C 2.849 26.212 6.247 15.57 6.247 15.57 L 8.115 9.253 Z"
      fill={gradient ? `url(#${gradient})` : 'none'}
    />

    <path
      d="M16.9385 22.9683C16.9385 23.3825 16.6027 23.7183 16.1885 23.7183C15.7743 23.7183 15.4385 23.3825 15.4385 22.9683C15.4385 22.554 15.7743 22.2183 16.1885 22.2183C16.6027 22.2183 16.9385 22.554 16.9385 22.9683Z"
      stroke="currentColor"
    />

    <path
      d="M25.6888 22.9683C25.6888 23.3825 25.3531 23.7183 24.9388 23.7183C24.5246 23.7183 24.1888 23.3825 24.1888 22.9683C24.1888 22.554 24.5246 22.2183 24.9388 22.2183C25.3531 22.2183 25.6888 22.554 25.6888 22.9683Z"
      stroke="currentColor"
    />

    <path
      d="M11.8138 27.3433C14.5222 28.3849 21.6888 29.8433 28.6888 27.3433"
      stroke="currentColor"
    />

    <path
      d="M28.6888 13.0979C25.9805 12.0562 18.8138 10.5979 11.8138 13.0979"
      stroke="currentColor"
    />

    <path
      d="M24.486 28.4535L26.3766 32.2035C26.4982 32.4592 26.7051 32.6646 26.9618 32.7844C27.2184 32.9041 27.5087 32.9308 27.7828 32.8597C31.611 31.9222 34.9235 30.2972 37.3297 28.2035C37.5111 28.0434 37.6429 27.8348 37.7093 27.6021C37.7758 27.3695 37.7742 27.1227 37.7047 26.891L32.4078 9.2035C32.3563 9.02249 32.263 8.8561 32.1354 8.7177C32.0079 8.5793 31.8497 8.47275 31.6735 8.40662C30.177 7.79267 28.6293 7.31165 27.0485 6.96912C26.7447 6.90259 26.427 6.95024 26.1561 7.10299C25.8852 7.25573 25.68 7.50287 25.5797 7.79725L24.3453 11.5316"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M16.0481 28.4535L14.1575 32.2035C14.0358 32.4592 13.8289 32.6646 13.5723 32.7844C13.3157 32.9041 13.0254 32.9308 12.7512 32.8597C8.92312 31.9222 5.61062 30.2972 3.20437 28.2035C3.02296 28.0434 2.89122 27.8348 2.82476 27.6021C2.7583 27.3695 2.7599 27.1227 2.82937 26.891L8.12624 9.2035C8.17779 9.02249 8.27109 8.8561 8.39863 8.7177C8.52618 8.5793 8.68441 8.47275 8.86062 8.40662C10.3571 7.79267 11.9048 7.31165 13.4856 6.96912C13.7894 6.90259 14.1071 6.95024 14.378 7.10299C14.6489 7.25573 14.8541 7.50287 14.9544 7.79725L16.1887 11.5316"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default DiscordLargeIcon;
