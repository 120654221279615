import { TLinearGradients } from '@/shared/types';

interface LinearGradientProps {
  id?: TLinearGradients;
}

const TLinearGradientColors: Record<TLinearGradients, { start: string; stop: string }> = {
  juneBudConifer: { start: '#d8d23c', stop: '#a2d56a' }
};

export const LinearGradient = ({ id = 'juneBudConifer' }: LinearGradientProps) => (
  <linearGradient
    id={id}
    x1="13.07%"
    x2="0%"
    y1="90.09%"
    y2="0%"
    gradientTransform="rotate(257.89)"
  >
    <stop stopColor={TLinearGradientColors[id].start} offset="0%" />
    <stop stopColor={TLinearGradientColors[id].stop} offset="100%" />
  </linearGradient>
);
