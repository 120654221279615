import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from '@/components/modal';
import Route from '@/config/routes/types';

export const PrivacyPage = () => {
  const navigate = useNavigate();

  const handleNavigateHome = useCallback(() => {
    navigate(Route.ROOT);
  }, [navigate]);

  return (
    <Modal.Frame
      classes="w-4/5 sm:w-2/3 md:w-3/5 xl:w-2/5 h-fit flex flex-col m-auto animate-slide-bottom-fast"
      closeOnClickOutside
      closeOnEsc
      color="dark"
      id="privacy-modal"
      onClose={handleNavigateHome}
      open
      portal
    >
      <Modal.Header centered size="md" title="Privacy" />

      <Modal.Body classes="flex flex-col gap-5 px-2" size='md'>
        <div className="text-center">Text of privacy.</div>
      </Modal.Body>
    </Modal.Frame>
  );
};
