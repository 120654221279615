import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import home from '@/pages/home-page/state/slice';
import { api } from './services/api';

const rootPersistConfig = {
  key: 'root',
  storage,
  version: 2,
  whitelist: []
};

const homePersistConfig = {
  key: 'home',
  blacklist: ['sectionsInfo', 'ability'],
  storage,
  version: 2
};

const rootReducer = combineReducers({
  home: persistReducer(homePersistConfig, home),
  [api.reducerPath]: api.reducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
