import { api } from './api';

export const contactApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactUs: build.mutation<string, { name: string; email: string; message: string }>({
      query: ({ name, email, message }) => ({
        url: '',
        method: 'POST',
        body: {
          name,
          mail: email,
          message
        },
        responseHandler: (response) => response.text()
      })
    })
  })
});

export const { useContactUsMutation } = contactApi;
