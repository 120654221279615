import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface BarsTopRightIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const BarsTopRightIcon = ({ color, gradient }: BarsTopRightIconProps) => (
  <svg
    className={clsx(color)}
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.996094"
      width="18.087"
      height="3.99777"
      rx="1.99888"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <rect
      y="9.99097"
      width="26"
      height="3.99777"
      rx="1.99888"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <rect
      y="18.9861"
      width="26"
      height="3.99777"
      rx="1.99888"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default BarsTopRightIcon;
