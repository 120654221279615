import { ChangeEvent, InputHTMLAttributes, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { TIconNames, TInputColor, TInputVariant } from '@/shared/types';

type InputType = 'text' | 'email' | 'password' | 'search' | 'textarea';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, 'onChange' | 'size'> {
  atEnd?: boolean;
  classes?: string;
  color?: TInputColor;
  error?: boolean;
  helperText?: string;
  icon?: TIconNames;
  onChange: (value: string) => void;
  rows?: number;
  size?: keyof typeof InputSize;
  type?: InputType;
  variant?: TInputVariant;
}

const InputIconPadding = {
  default: { start: 'pr-1 pl-10', end: 'pr-10 pl-0' },
  filled: { start: 'pr-2.5 pl-11', end: 'pr-11 pl-2.5' },
  outlined: { start: 'pr-1 sm:pr-3 pl-10 sm:pl-12', end: 'pr-10 sm:pr-12 pl-3 sm:pl-5' },
  plain: { start: 'pr-1 sm:pr-3 pl-10 sm:pl-12', end: 'pr-10 sm:pr-12 pl-3 sm:pl-5' }
};

const InputPadding = {
  default: 'px-0',
  filled: 'px-2.5',
  outlined: 'px-3 sm:px-5',
  plain: 'p-0'
};

const InputVariant = {
  default: 'bg-transparent border-0 border-b-2',
  filled: 'bg-gray-50 border-0 border-b-2 rounded-t-md',
  outlined: 'bg-transparent border rounded-md',
  plain: 'bg-transparent'
};

const InputSmallPadding = {
  default: 'py-1',
  filled: 'pt-1 pb-0.5',
  outlined: 'pt-2.5 pb-2',
  plain: 'py-0'
};

const InputMediumPadding = {
  default: 'py-2.5',
  filled: 'pt-5 pb-2.5',
  outlined: 'pt-4 pb-4',
  plain: 'py-0'
};

const InputSize = {
  sm: InputSmallPadding,
  md: InputMediumPadding
};

const InputTextSize = {
  sm: 'text-sm',
  md: 'text-base'
};

const InputColor = {
  default: 'border-ash-grey focus:border-cloudy-grey',
  disabled: 'border-mercury',
  error: 'border-coral-pink placeholder-coral-pink/60',
  dark: 'border-bleached-cedar placeholder-mountain-mist'
};

const InputErrorTextColor = {
  default: 'text-black',
  disabled: '',
  error: '',
  dark: 'text-white'
};

export const Input = ({
  atEnd = false,
  classes,
  color = 'default',
  disabled,
  error,
  icon,
  id,
  maxLength,
  onChange,
  placeholder,
  size = 'md',
  rows = 3,
  type = 'text',
  variant = 'default',
  ...props
}: InputProps) => {
  const isTextArea = type === 'textarea';
  const InputComponent = isTextArea ? 'textarea' : 'input';
  const inputColor = (disabled && 'disabled') || (error && 'error') || color;
  const inputIconPosition = atEnd ? 'end' : 'start';

  const remainChars = useMemo(() => {
    const stringLength = props.value?.toString().length || 0;
    let number = 0;

    if (isTextArea && maxLength) {
      number = maxLength - stringLength;
    }

    return number;
  }, [isTextArea, maxLength, props.value]);

  const inputProps = {
    ...(isTextArea && { rows, maxLength }),
    ...props
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;

      onChange?.(value);
    },
    [onChange]
  );

  return (
    <>
      <InputComponent
        aria-describedby="error-helper"
        className={clsx(
          'w-full block text-left font-mono font-normal tracking-dense appearance-none focus:outline-none peer',
          {
            'resize-none': isTextArea
          },
          icon ? InputIconPadding[variant][inputIconPosition] : InputPadding[variant],
          InputColor[inputColor],
          InputErrorTextColor[color],
          InputVariant[variant],
          InputTextSize[size],
          InputSize[size][variant],
          classes
        )}
        disabled={disabled}
        id={id}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        {...inputProps}
      />

      {isTextArea && maxLength && (
        <div className="absolute bottom-0 right-0">
          <div className="text-semi-xs text-left text-mountain-mist font-mono font-normal tracking-dense px-1.5 py-0.5">
            {remainChars} left
          </div>
        </div>
      )}
    </>
  );
};
