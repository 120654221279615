import { THomeCapability } from '../../state/types';
import { HomePageCapabilities } from './capabilities';
import { HomePageMissionInfo } from './home-page-mission-info';

const data: {
  title: string;
  capabilities: THomeCapability[];
} = {
  title: 'Use Cases',
  capabilities: [
    {
      key: 'creator',
      title: 'Creator',
      position: [0, 1.15, 1.1],
      description:
        // 'Tell amazing stories using an easy to use interface. Design engaging capsules, no technical skills needed. Focus on creating and interacting with your audience'
        'Individuals and teams of content creators, including photographers, videographers, graphic designers, writers, and artists, who require a source of high-quality digital assets and a platform to showcase their work.'
    },
    {
      key: 'marketing',
      title: 'Marketing',
      position: [-1.6, 1, -0.05],
      description:
        // 'Increase your brand awareness by building interactive online shopping experiences, VR/AR showrooms and brand portfolios',
        'Enhance marketing campaigns with immersive content, high-quality visuals, reward brand engagement and benefit from blockchain-powered copyright protection'
    },
    {
      key: 'education',
      title: 'Education',
      position: [1.3, 1.05, -0.9],
      description:
        // 'Engage with your favorite brands and new ways to interact and connect. Gain rewards and share with friends'
        'Use the platform for educational purposes and offering immersive learning experiences. Foster student collaboration and capitalize on educational content'
    },
    {
      key: 'ecommerce',
      title: 'E-commerce',
      position: [1.6, -1, 0.3],
      description:
        // 'Become a partner, collaborate closely with your favorite creators and brands. By helping spread the word, earn and increase your visibility. Expand your network, so many possibilities'
        'Increase your brand awareness by building interactive online shopping experiences. Amaze potential customers with engaging virtual tours and showrooms'
    },
    {
      key: 'influencer',
      title: 'Influencer',
      position: [-1.1, -1.2, 1.3],
      description:
        'Leverage your personal brand to partner with fans and businesses to elevate your brand identiy. Showcase your creativity, promote & host events and participate in product launch. Build a collaborative economy with your followers for your niche market'
    }
  ]
};

export const HomePageMission = () => {
  const { capabilities, ...infoProps } = data;

  return (
    <>
      <HomePageMissionInfo {...infoProps} />

      <HomePageCapabilities capabilities={capabilities} />
    </>
  );
};
