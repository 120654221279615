import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToContent = <T extends HTMLElement = HTMLDivElement>(): {
  ref: RefObject<T>;
  onScrollToContent: () => void;
} => {
  const { hash, key } = useLocation();

  const elementRef = useRef<T>(null);

  const onScrollToContent = useCallback(() => {
    elementRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }, 0);

    return () => {
      clearTimeout(scrollTimer);
    };
  }, [hash, key]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  return { ref: elementRef, onScrollToContent };
};
