import { Input, InputProps } from '../common/input';
import { Label } from '../common/label';

interface TextFieldLabeledProps extends InputProps {
  label?: string;
}

export const TextFieldLabeled = ({
  error,
  id,
  label,
  placeholder = ' ',
  variant,
  ...inputProps
}: TextFieldLabeledProps) => (
  <>
    <Input error={error} id={id} placeholder={placeholder} variant={variant} {...inputProps} />

    {label && (
      <Label
        atEnd={inputProps.atEnd}
        color={inputProps.color}
        disabled={inputProps.disabled}
        error={error}
        icon={inputProps.icon}
        id={id}
        label={label}
        placeholder={placeholder}
        variant={variant}
      />
    )}
  </>
);
