import { useCallback } from 'react';

export const useDisableOverflowRescaling = () => {
  const onOverflow = useCallback((isOpen: boolean | undefined) => {
    const scrollbarWidth = `${window.innerWidth - document.body.clientWidth}px`;

    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    document.body.style.paddingRight = isOpen ? scrollbarWidth : '0px';
    document.body.classList.add('bg-liver');
  }, []);

  const onOverflowReset = useCallback(() => {
    document.body.style.overflow = 'unset';
    document.body.style.paddingRight = '0px';
    document.body.classList.remove('bg-liver');
  }, []);

  return {
    onOverflow,
    onOverflowReset
  };
};
