import { VideoGallery } from '@/components/video-gallery';

import communityJpg from '@/assets/images/community-poster.jpg';
import creativeJpg from '@/assets/images/creative-poster.jpg';
import ideasJpg from '@/assets/images/ideas-poster.jpg';
import socialJpg from '@/assets/images/social-poster.jpg';
import workBridgesJpg from '@/assets/images/work-bridges-poster.jpg';
import communityMp4 from '@/assets/videos/community.mp4';
import communityWebm from '@/assets/videos/community.webm';
import creativeMp4 from '@/assets/videos/creative.mp4';
import creativeWebm from '@/assets/videos/creative.webm';
import ideasMp4 from '@/assets/videos/ideas.mp4';
import ideasWebm from '@/assets/videos/ideas.webm';
import socialMp4 from '@/assets/videos/social.mp4';
import socialWebm from '@/assets/videos/social.webm';
import workBridgesMp4 from '@/assets/videos/work-bridges.mp4';
import workBridgesWebm from '@/assets/videos/work-bridges.webm';

const videos = [
  {
    // Enjoy Exclusive Experiences
    // Empower Creative Engagement
    // Elevate Your Brand
    poster: workBridgesJpg,
    sources: [
      { src: workBridgesWebm, type: 'video/webm' },
      { src: workBridgesMp4, type: 'video/mp4' }
    ],
    title: 'Streamline Content Creation' //fashion designer
  },
  {
    poster: socialJpg,
    sources: [
      { src: socialWebm, type: 'video/webm' },
      { src: socialMp4, type: 'video/mp4' }
    ],
    title: 'Innovate, Collaborate, Monetize' //marketing
  },
  {
    poster: communityJpg,
    sources: [
      { src: communityWebm, type: 'video/webm' },
      { src: communityMp4, type: 'video/mp4' }
    ],
    title: 'Game On, No Code Required' //skater
  },
  {
    poster: ideasJpg,
    sources: [
      { src: ideasWebm, type: 'video/webm' },
      { src: ideasMp4, type: 'video/mp4' }
    ],
    title: 'Discover Immersive Experiences' //restaurant
  },
  {
    poster: creativeJpg,
    sources: [
      { src: creativeWebm, type: 'video/webm' },
      { src: creativeMp4, type: 'video/mp4' }
    ],
    title: 'One Platform, Endless Creativity' //concert
  }
];

export const HomePageShowcase = () => <VideoGallery classes="w-full h-full" videos={videos} />;
