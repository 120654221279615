import { Html } from '@react-three/drei';
import clsx from 'clsx';

import { TPosition } from '@/shared/types';
import { ThreeDimensionalPickerColors } from './state/types';

interface ThreeDimensionalAnnotationProps {
  color?: ThreeDimensionalPickerColors;
  distanceFactor?: number;
  isActive: boolean;
  onClick: () => void;
  position: TPosition;
  title: string;
}

const AnnotationColor: Record<ThreeDimensionalPickerColors, string> = {
  green: 'hover:border-mantis',
  orange: 'hover:border-squash'
};

export const ThreeDimensionalAnnotation = ({
  color = 'green',
  distanceFactor = 3,
  isActive = false,
  onClick,
  position: [x, y, z],
  title
}: ThreeDimensionalAnnotationProps) => {
  return (
    <mesh position={[x, y, z]}>
      <Html center distanceFactor={distanceFactor}>
        <div
          className={clsx(
            'w-28 sm:w-48.75 flex justify-center border-2 rounded-full backdrop-blur-almost-md py-2 px-1 sm:py-2.5 cursor-pointer',
            isActive ? 'border-white bg-white/50' : 'border-transparent bg-baltic-sea/50',
            AnnotationColor[color]
          )}
          onClick={onClick}
        >
          <div
            className={clsx(
              'text-sm sm:text-2xl text-left leading-2 sm:leading-8.5 font-mono font-normal tracking-tighter select-none sm:px-3 truncate',
              isActive ? 'text-black' : 'text-white'
            )}
          >
            {title}
          </div>
        </div>
      </Html>
    </mesh>
  );
};
