import { Container } from '@/components/common/container';
import Route from '@/config/routes/types';
import { ContactIcon } from '../../state/types';
import { LandingLayoutFooterIconLink } from './landing-layout-footer-icon-link';

export const LandingLayoutFooter = () => {
  const contactIcons: ContactIcon[] = [
    {
      icon: 'letter',
      to: Route.CONTACT_US
    },
    {
      icon: 'twitterLarge',
      href: 'https://twitter.com/@tangliusIO'
    },
    // {
    //   icon: 'discordLarge',
    //   href: 'https://discord.gg/'
    // },
    {
      icon: 'instagramLarge',
      href: 'https://instagram.com/tanglius'
    }
  ];

  return (
    <Container as="footer" classes="w-full bg-smoky-black pt-3 pb-12 sm:pb-14" id="contact-us">
      <div className="container w-full flex flex-col items-center gap-y-3 lg:gap-y-10 xl:gap-y-18 px-4 sm:px-22 mx-auto">
        <div className="w-full flex justify-start py-3">
          <div className="text-3xl sm:text-semi-8xl text-left text-white leading-7.5 sm:leading-21 font-outfit font-normal tracking-tighter">
            Contact Us
          </div>
        </div>

        <div className="flex flex-1 gap-5 sm:gap-9 justify-center py-6 sm:py-8">
          {contactIcons.map((contact, contactIdx) => (
            <LandingLayoutFooterIconLink key={contactIdx} {...contact} />
          ))}
        </div>
      </div>
    </Container>
  );
};
