import { useCallback, useLayoutEffect, useState } from 'react';

import { useContainerSize } from '@/shared/hooks/use-container-size';
import { Keyframes } from '../common/keyframes';
import { ListPickerItem, ListPickerItemStylesProps } from './list-picker-item';
import { ListPickerShadow } from './list-picker-shadow';
import {
  ITEM_HEIGHT_MIN,
  ITEM_MARGIN_BOTTOM,
  PickerDirection,
  VISIBLE_ITEMS_NUM
} from './state/constants';
import { TListPickerItem } from './state/types';
import { ObjectValues } from '@/shared/utils/types';

interface ListPickerProps extends ListPickerItemStylesProps {
  direction?: ObjectValues<typeof PickerDirection>;
  id?: string;
  isDownEnable?: boolean;
  isUpEnable?: boolean;
  isUnidirectional?: boolean;
  items: TListPickerItem[];
  onChange: (direction: TListPickerItem) => void;
  value: TListPickerItem;
}

export const ListPicker = ({
  color = 'plain',
  direction = PickerDirection.DOWN,
  id,
  isDownEnable = false,
  isUpEnable = false,
  isUnidirectional = false,
  items,
  onChange,
  value
}: ListPickerProps) => {
  const [containerRef, { height: containerHeight }] = useContainerSize();

  const [parentHeight, setParentHeight] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);

  const initialIdx = items.findIndex(({ text }: TListPickerItem) => text === value.text);
  const valueIdx = initialIdx >= 0 ? initialIdx : 0;

  const visibleCentralIdx = Math.ceil(VISIBLE_ITEMS_NUM / 2);
  const sideItemsNum = visibleCentralIdx - 1;
  const botItemsNum = visibleCentralIdx + sideItemsNum;

  const [listItems, setListItems] = useState([
    ...items.slice(-(sideItemsNum * 2)),
    ...items.slice(0, botItemsNum)
  ]);
  const centeralIdx = Math.floor(listItems.length / 2);

  const [animateFromIdx, setAnimateFromIdx] = useState(0);

  const handleItemClicked = useCallback(
    (itemIdx: number, item: TListPickerItem) => {
      const originalIdx = items.findIndex(({ text }) => text === item.text);

      if (
        ((isUnidirectional && direction === PickerDirection.DOWN) || !isUnidirectional) &&
        itemIdx > centeralIdx
      ) {
        const itemIndex = itemIdx - botItemsNum;
        const shift = itemIndex + 1;
        const from = (centeralIdx - itemIndex + originalIdx) % items.length;
        const to = from + shift;

        let botItems = items.slice(from, to);
        if (to > items.length) {
          botItems = [...botItems, ...items.slice(0, to - items.length)];
        }

        const newItems = [...listItems, ...botItems].slice(botItems.length);

        setListItems(newItems);
        setAnimateFromIdx(centeralIdx - itemIdx);
        onChange(items[originalIdx]);
      }

      if (
        ((isUnidirectional && direction === PickerDirection.UP) || !isUnidirectional) &&
        itemIdx < centeralIdx
      ) {
        const shift = centeralIdx - itemIdx;
        const to = originalIdx - itemIdx;
        const from = to - shift;
        let topItems = items.slice(from, to);
        if (from < 0) {
          topItems = [...items.slice(items.length + from, items.length), ...items.slice(0, to)];
        }
        const newItems = [...topItems, ...listItems].slice(0, -topItems.length);

        setListItems(newItems);
        setAnimateFromIdx(centeralIdx - itemIdx);
        onChange(items[originalIdx]);
      }
    },
    [botItemsNum, centeralIdx, direction, isUnidirectional, items, listItems, onChange]
  );

  useLayoutEffect(() => {
    setParentHeight(Math.floor(containerHeight) || ITEM_HEIGHT_MIN * VISIBLE_ITEMS_NUM);
    setItemHeight(
      (containerHeight - ITEM_MARGIN_BOTTOM * (VISIBLE_ITEMS_NUM - 1)) / VISIBLE_ITEMS_NUM ||
        ITEM_HEIGHT_MIN
    );
  }, [containerHeight, items]);

  return (
    <>
      <Keyframes
        name={id || 'scroll-vertical'}
        from={{
          transform: `translateY(${-((itemHeight + ITEM_MARGIN_BOTTOM) * animateFromIdx)}px)`
        }}
        to={{ transform: 'translateY(0)' }}
      />

      <div className="relative w-full h-full" ref={containerRef} style={{ height: parentHeight }}>
        <div className="absolute inset-0 w-full h-full items-center overflow-hidden">
          <div
            style={{
              transform: `translateY(-${(itemHeight + ITEM_MARGIN_BOTTOM) * sideItemsNum}px)`
            }}
          >
            <div
              className="will-change-transform"
              key={valueIdx}
              style={{
                animation: `${Math.abs(animateFromIdx) * 0.3}s cubic-bezier(0.4, 0, 0.2, 1) 0s 1 ${
                  id || 'scroll-vertical'
                }`
              }}
            >
              {listItems.map((item, idx) => (
                <ListPickerItem
                  classes="hover:cursor-pointer"
                  color={color}
                  containerHeight={itemHeight}
                  isActive={idx === centeralIdx}
                  key={idx}
                  onItemClicked={() => handleItemClicked(idx, item)}
                  textColor={
                    idx < centeralIdx ? 'shadow-t' : idx > centeralIdx ? 'shadow-b' : 'default'
                  }
                  {...item}
                />
              ))}
            </div>
          </div>
        </div>

        <ListPickerShadow
          itemHeight={itemHeight}
          isDownEnable={isDownEnable}
          isUpEnable={isUpEnable}
        />
      </div>
    </>
  );
};
