import clsx from 'clsx';

import { LinearGradient } from '@/components/common/linear-gradient';
import { TLinearGradients } from '@/shared/types';

interface CrossBoldIconProps {
  color?: string;
  gradient?: TLinearGradients;
}

const CrossBoldIcon = ({ color, gradient }: CrossBoldIconProps) => (
  <svg
    className={clsx(color)}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="18.3848"
      width="26"
      height="4"
      rx="2"
      transform="rotate(-45 0 18.3848)"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <rect
      x="2.82837"
      width="26"
      height="4"
      rx="2"
      transform="rotate(45 2.82837 0)"
      fill={gradient ? `url(#${gradient})` : 'currentColor'}
    />

    <LinearGradient id={gradient} />
  </svg>
);

export default CrossBoldIcon;
