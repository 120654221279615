import clsx from 'clsx';

interface ChevronUpIconProps {
  color?: string;
}

const ChevronUpIcon = ({ color }: ChevronUpIconProps) => (
  <svg
    className={clsx(color)}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

export default ChevronUpIcon;
