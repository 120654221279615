export const ITEM_HEIGHT_MIN = 109;
export const ITEM_MARGIN_BOTTOM = 10;
export const VISIBLE_ITEMS_NUM = 3;

export enum PickerItemColors {
  plain = 'border-june-bud',
  secondary = 'border-dark-orange'
}

const PickerDirection = {
  DOWN: 'down',
  UP: 'up'
} as const;

export { PickerDirection };
