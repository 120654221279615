import clsx from 'clsx';

import { useIsElementShown } from '@/shared/hooks/use-is-element-shown';

interface HomePageCapabilitiesInfoProps {
  title?: string;
}

export const HomePageCapabilitiesInfo = ({ title }: HomePageCapabilitiesInfoProps) => {
  const { isShown, ref } = useIsElementShown();

  return (
    <div
      className={clsx(
        'flex flex-col gap-2.5 will-change-transform',
        isShown
          ? 'translate-x-0 opacity-100 transition-all ease-in duration-700 delay-300'
          : 'translate-x-36 lg:translate-x-1/2 opacity-0'
      )}
      ref={ref}
    >
      <div
        key={title}
        className="text-sm sm:text-2xl text-left text-white leading-6.5 sm:leading-8 font-mono font-normal tracking-dense animate-fade-in"
      >
        {title}
      </div>
    </div>
  );
};
