import clsx from 'clsx';

import { TIconNames, TInputColor, TInputVariant } from '@/shared/types';

interface LabelProps {
  atEnd?: boolean;
  classes?: string;
  color?: TInputColor;
  disabled?: boolean;
  error?: boolean;
  icon?: TIconNames;
  id?: string;
  label: string;
  placeholder?: string;
  variant?: TInputVariant;
}
const LabelIconLeft = {
  default: { start: 'left-10 peer-focus:left-10', end: 'peer-focus:left-0' },
  filled: { start: 'left-11', end: 'left-2.5' },
  outlined: { start: 'left-9 sm:left-11', end: 'left-2 sm:left-4' },
  plain: { start: 'left-9 sm:left-11', end: 'left-2 sm:left-4' }
};

const LabelVariant = {
  default: 'top-3 -translate-y-6 -z-10 peer-focus:-translate-y-6',
  filled: 'top-4 -translate-y-4 z-10 peer-focus:-translate-y-4',
  outlined:
    'top-2 -translate-y-5 px-1.5 z-10 peer-focus:top-2 peer-focus:-translate-y-5 peer-focus:px-1.5',
  plain:
    'top-2 -translate-y-5 px-1.5 z-10 peer-focus:top-2 peer-focus:-translate-y-5 peer-focus:px-1.5'
};

const LabelColor = {
  default: 'text-mountain-mist peer-focus:text-cloudy-grey',
  disabled: 'text-ash-grey',
  error: 'text-coral-pink',
  dark: 'text-mountain-mist'
};

const PlaceholderlessVariant = {
  default: 'peer-placeholder-shown:translate-y-0',
  filled: 'peer-placeholder-shown:translate-y-0',
  outlined: 'peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2',
  plain: 'peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2'
};

const LabelDefaultBg = {
  default: 'bg-transparent',
  disabled: 'bg-transparent',
  error: 'bg-transparent',
  dark: 'bg-transparent'
};

const LabelFilledBg = {
  default: 'bg-white',
  disabled: 'bg-white',
  error: 'bg-white',
  dark: 'bg-cinder'
};

const LabelBgVariant = {
  default: LabelDefaultBg,
  filled: LabelDefaultBg,
  outlined: LabelFilledBg,
  plain: LabelFilledBg
};

export const Label = ({
  atEnd = false,
  classes,
  color = 'default',
  disabled,
  error,
  icon,
  id,
  label,
  placeholder,
  variant = 'default'
}: LabelProps) => {
  const labelColor = (disabled && 'disabled') || (error && 'error') || color;
  const labelIconPosition = !icon || atEnd ? 'end' : 'start';

  return (
    <label
      className={clsx(
        'absolute origin-[0] text-base text-left font-mono font-normal tracking-dense whitespace-nowrap will-change-auto scale-75 transition-all ease-in-out duration-300 peer-focus:scale-75',
        {
          [`peer-placeholder-shown:scale-100 ${PlaceholderlessVariant[variant]}`]:
            !placeholder?.trim()
        },
        LabelIconLeft[variant][labelIconPosition],
        LabelColor[labelColor],
        LabelVariant[variant],
        LabelBgVariant[variant][color],
        classes
      )}
      htmlFor={id}
    >
      {label}
    </label>
  );
};
