import { MouseEvent } from 'react';
import clsx from 'clsx';

import { Image } from '../common/image';
import { ListPickerColors } from './state/types';
import { ITEM_MARGIN_BOTTOM, PickerItemColors } from './state/constants';

export interface ListPickerItemStylesProps {
  color?: ListPickerColors;
}

interface ListPickerItemProps extends ListPickerItemStylesProps {
  classes?: string;
  containerHeight?: number;
  icon?: string;
  isActive: boolean;
  onItemClicked?: (e: MouseEvent<HTMLDivElement>) => void;
  text: string;
  textColor?: keyof typeof ListPickerItemTextColor;
  title?: string;
}

const ListPickerItemTextColor = {
  default: 'text-white',
  'shadow-t': 'text-transparent bg-clip-text bg-gradient-to-t from-spicy-pink to-transparent',
  'shadow-b': 'text-transparent bg-clip-text bg-gradient-to-t from-transparent to-spicy-pink'
};

export const ListPickerItem = ({
  classes,
  color = 'plain',
  icon,
  isActive,
  containerHeight = 0,
  onItemClicked,
  text,
  textColor = 'default',
  title
}: ListPickerItemProps) => {
  const height = containerHeight || 'auto';

  return (
    <div
      key={text}
      className={clsx(
        'relative w-full h-full flex items-center border-l-2 bg-gondola overflow-hidden select-none',
        isActive ? PickerItemColors[color] : 'border-transparent',
        classes
      )}
      style={{
        minHeight: height,
        maxHeight: height,
        height: height,
        marginBottom: containerHeight ? ITEM_MARGIN_BOTTOM : 0
      }}
      onClick={onItemClicked}
    >
      <div
        className={clsx(
          containerHeight ? 'pl-7' : 'px-8 py-4.5',
          icon ? 'w-4/5 sm:w-3/4 md:w-2/3' : 'w-full',
          { 'flex flex-col gap-3.5': title }
        )}
      >
        {title && (
          <div className="text-2xl text-left text-white leading-8.5 font-outfit font-normal tracking-tighter">
            {title}
          </div>
        )}

        <div
          className={clsx(
            'text-sm text-left leading-6.5 font-mono font-normal tracking-dense transition-all ease-out duration-300',
            ListPickerItemTextColor[textColor]
          )}
        >
          {text}
        </div>
      </div>

      {icon && (
        <Image
          alt="community"
          classes={clsx(
            'w-1/5 sm:w-1/4 md:w-1/3 h-full pt-2.25 transition-all ease-out duration-300',
            isActive ? 'opacity-100' : 'opacity-0'
          )}
          fit="cover"
          fitPosition="left-bottom"
          layout="vertical"
          src={icon}
        />
      )}
    </div>
  );
};
