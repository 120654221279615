import { ReactNode } from 'react';

import { useWindowSize } from '@/shared/hooks/use-window-size';
import { LANDING_BAR_HEIGHT_MOBILE, LANDING_BAR_HEIGHT } from '../state/constants';

interface LandingLayoutMainProps {
  children: ReactNode;
}

export const LandingLayoutMain = ({ children }: LandingLayoutMainProps) => {
  const { width } = useWindowSize();
  const isLayoutCompact = width < 640;

  return (
    <main
      className="w-full bg-black"
      style={{
        marginTop: `-${isLayoutCompact ? LANDING_BAR_HEIGHT_MOBILE : LANDING_BAR_HEIGHT}px`
      }}
    >
      {children}
    </main>
  );
};
