import { CSSProperties } from 'react';

interface KeyframesProps {
  name: string;
  [key: string]: CSSProperties | string;
}

export const Keyframes = ({ name, ...keyframeProps }: KeyframesProps) => {
  const toCss = (cssObject: CSSProperties | string) =>
    typeof cssObject === 'string'
      ? cssObject
      : Object.keys(cssObject).reduce((accumulator, key) => {
          const cssKey = key.replace(/[A-Z]/g, (v) => `-${v.toLowerCase()}`);
          const cssValue = (cssObject as any)[key].toString().replace("'", '');
          return `${accumulator}${cssKey}:${cssValue};`;
        }, '');

  return (
    <style>
      {`@keyframes ${name} {
         ${Object.keys(keyframeProps)
           .map((key) => {
             return ['from', 'to'].includes(key)
               ? `${key} { ${toCss(keyframeProps[key])} }`
               : /^_[0-9]+$/.test(key)
               ? `${key.replace('_', '')}% { ${toCss(keyframeProps[key])} }`
               : '';
           })
           .join(' ')}
        }`}
    </style>
  );
};
