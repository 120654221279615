import { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { TVideoSlideSource } from './state/types';

interface VideoSlideProps {
  mirrored?: boolean;
  onEnded: () => void;
  poster: string;
  sources: TVideoSlideSource[];
  title?: string;
}

export const VideoSlide = ({
  mirrored = false,
  onEnded,
  poster,
  sources,
  title
}: VideoSlideProps) => {
  const handleEnded = useCallback(() => {
    onEnded();
  }, [onEnded]);

  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.play();
  }, []);

  return (
    <>
      <div className="flex h-full">
        <video
          autoPlay
          className={clsx('w-full h-full object-cover', { '-scale-x-100': mirrored })}
          muted
          playsInline
          poster={poster}
          preload="none"
          ref={ref}
          onEnded={handleEnded}
          key={title}
        >
          {sources.map((source, idx) => (
            <source key={idx} {...source} />
          ))}
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div
        className={clsx(
          'absolute inset-0 flex items-center bg-gradient-to-b from-black/20 to-black/20'
        )}
      >
        {title && (
          <div
            key={title}
            className={clsx(
              'animate-slide-bottom-slow w-full sm:w-7/12 pl-4 sm:pl-22 pr-4 sm:pr-0 will-change-transform transition-all ease-in duration-700'
            )}
          >
            <div className="text-5.5xl sm:text-6xl md:text-7xl lg:text-semi-8xl xl:text-semi-9xl text-center sm:text-left text-white leading-15 sm:leading-17 md:leading-21 lg:leading-28.25 font-outfit font-normal tracking-tighter">
              {title}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
