import clsx from 'clsx';

import { ListPickerItem } from '@/components/list-picker';
import { THomeAbilityCategory } from '@/pages/home-page/state/types';
import { useIsElementShown } from '@/shared/hooks/use-is-element-shown';

interface HomePageAbilityInfoProps {
  categories?: THomeAbilityCategory[];
  title?: string;
}

export const HomePageAbilityInfo = ({ categories, title }: HomePageAbilityInfoProps) => {
  const { isShown: isTitleShown, ref: titleRef } = useIsElementShown();
  const { isShown: isListShown, ref: listRef } = useIsElementShown();

  return (
    <div className="flex flex-col gap-7.75 lg:gap-13.5">
      <div
        className={clsx(
          'text-3xl lg:text-xs-5xl text-left text-white leading-7.5 lg:leading-17.75 font-outfit font-normal tracking-tighter will-change-transform',
          isTitleShown
            ? 'translate-x-0 opacity-100 transition-all ease-in duration-700'
            : 'translate-x-36 lg:translate-x-1/2 opacity-0'
        )}
        ref={titleRef}
      >
        {title}
      </div>

      <div
        className={clsx(
          'flex flex-col gap-2.5 will-change-transform',
          isListShown
            ? 'translate-x-0 opacity-100 transition-all ease-in duration-700 delay-300'
            : 'translate-x-36 lg:translate-x-1/2 opacity-0'
        )}
        ref={listRef}
      >
        {categories?.map((category, idx) => (
          <ListPickerItem
            classes="shadow-item-inner shadow-white/0 hover:shadow-white/70"
            isActive
            key={idx}
            {...category}
          />
        ))}
      </div>
    </div>
  );
};
