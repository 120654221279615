const LandingBrandLogoIcon = () => (
  <svg width="120" height="36" viewBox="0 0 120 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M95.831 28.3343C94.2787 28.3343 92.8947 27.9977 91.6791 27.3244C90.4821 26.6324 89.5377 25.6879 88.8457 24.491C88.1537 23.294 87.8077 21.9381 87.8077 20.4232V8.33203H91.623V20.5354C91.623 21.4144 91.8007 22.1718 92.156 22.8077C92.53 23.4436 93.035 23.9392 93.6709 24.2946C94.3255 24.6312 95.0455 24.7995 95.831 24.7995C96.6352 24.7995 97.3459 24.6312 97.9631 24.2946C98.5803 23.9392 99.0666 23.4436 99.4219 22.8077C99.796 22.1718 99.983 21.4237 99.983 20.5634V8.33203H103.826V20.4512C103.826 21.9661 103.48 23.3221 102.788 24.519C102.096 25.6973 101.152 26.6324 99.9549 27.3244C98.7767 27.9977 97.402 28.3343 95.831 28.3343Z"
      fill="url(#paint0_linear_16_37)"
    />
    <path
      d="M111.695 28.3343C110.105 28.3343 108.74 28.0631 107.599 27.5208C106.477 26.9784 105.457 26.1648 104.541 25.0801L107.066 22.5552C107.646 23.3221 108.319 23.9205 109.086 24.3507C109.853 24.7621 110.788 24.9679 111.891 24.9679C112.882 24.9679 113.668 24.7715 114.248 24.3787C114.846 23.986 115.145 23.4436 115.145 22.7516C115.145 22.1531 114.977 21.6669 114.64 21.2928C114.304 20.9188 113.855 20.6008 113.294 20.339C112.751 20.0772 112.144 19.8434 111.47 19.6377C110.816 19.4132 110.152 19.1608 109.478 18.8802C108.824 18.5997 108.216 18.2537 107.655 17.8422C107.113 17.4121 106.673 16.8697 106.336 16.2151C106 15.5418 105.832 14.7002 105.832 13.6903C105.832 12.4933 106.121 11.474 106.701 10.6324C107.281 9.79082 108.076 9.14559 109.086 8.69673C110.096 8.24787 111.237 8.02344 112.508 8.02344C113.855 8.02344 115.071 8.27592 116.155 8.78089C117.24 9.28585 118.128 9.94044 118.82 10.7446L116.296 13.2695C115.716 12.6336 115.117 12.166 114.5 11.8668C113.902 11.5676 113.219 11.4179 112.452 11.4179C111.573 11.4179 110.881 11.5863 110.376 11.9229C109.871 12.2595 109.619 12.7458 109.619 13.3817C109.619 13.9241 109.787 14.3636 110.124 14.7002C110.46 15.0369 110.9 15.3267 111.442 15.5699C112.003 15.813 112.611 16.0468 113.266 16.2712C113.939 16.4957 114.603 16.7481 115.258 17.0287C115.931 17.3092 116.539 17.6739 117.081 18.1228C117.642 18.5716 118.091 19.1514 118.428 19.8621C118.764 20.5541 118.933 21.4144 118.933 22.443C118.933 24.2759 118.287 25.716 116.997 26.7633C115.706 27.8106 113.939 28.3343 111.695 28.3343Z"
      fill="url(#paint1_linear_16_37)"
    />
    <path
      d="M6.90121 28.0538V8.75283H10.7446V28.0538H6.90121ZM0.617188 11.7265V8.33203H17.0286V11.7265H0.617188Z"
      fill="white"
    />
    <path
      d="M14.2263 28.0538L22.1655 8.33203H25.2794L33.1906 28.0538H29.1228L23.0071 11.8668H24.4098L18.2099 28.0538H14.2263ZM18.4343 24.3787V21.1806H29.0386V24.3787H18.4343Z"
      fill="white"
    />
    <path
      d="M34.1172 28.0538V8.33203H36.7823L37.9325 12.1473V28.0538H34.1172ZM47.7513 28.0538L36.0529 12.9048L36.7823 8.33203L48.4807 23.481L47.7513 28.0538ZM47.7513 28.0538L46.6572 24.3507V8.33203H50.5006V28.0538H47.7513Z"
      fill="white"
    />
    <path
      d="M62.2148 28.3343C60.8121 28.3343 59.4936 28.0818 58.2592 27.5769C57.0435 27.0532 55.9681 26.3238 55.033 25.3887C54.1166 24.4536 53.3966 23.3782 52.8729 22.1625C52.3679 20.9281 52.1154 19.6003 52.1154 18.1789C52.1154 16.7388 52.3773 15.4109 52.9009 14.1952C53.4246 12.9609 54.154 11.8855 55.0891 10.9691C56.0243 10.034 57.1183 9.31391 58.3714 8.80894C59.6245 8.28527 60.9804 8.02344 62.4392 8.02344C64.0663 8.02344 65.5625 8.33203 66.9278 8.94921C68.2931 9.54769 69.4246 10.3986 70.3223 11.5021L67.7413 14.083C67.1242 13.2788 66.3574 12.6616 65.4409 12.2315C64.5245 11.8013 63.5146 11.5863 62.4111 11.5863C61.1581 11.5863 60.0453 11.8668 59.0728 12.4279C58.1189 12.9702 57.3708 13.737 56.8285 14.7283C56.2861 15.7008 56.0149 16.8416 56.0149 18.1508C56.0149 19.46 56.2767 20.6195 56.8004 21.6295C57.3428 22.6207 58.0722 23.3969 58.9886 23.9579C59.9237 24.519 60.9898 24.7995 62.1867 24.7995C63.4211 24.7995 64.4778 24.5751 65.3568 24.1263C66.2358 23.6587 66.9091 22.9854 67.3766 22.1064C67.8442 21.2087 68.078 20.1146 68.078 18.8241L70.5748 20.5354L61.9623 20.4512V17.1409H72.0055V17.6739C72.0055 20.0304 71.5847 22.0035 70.7431 23.5932C69.9015 25.1642 68.7419 26.3518 67.2644 27.1561C65.8056 27.9416 64.1224 28.3343 62.2148 28.3343Z"
      fill="white"
    />
    <path
      d="M73.5294 28.0538V8.33203H77.3447V28.0538H73.5294ZM76.3348 28.0538V24.6593H86.378V28.0538H76.3348Z"
      fill="white"
    />
    <path d="M87.7151 28.0538V8.33203H91.5304V28.0538H87.7151Z" fill="white" />
    <path d="M87.651 1.07373H91.5436V4.83212H87.651V1.07373Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_16_37"
        x1="114.401"
        y1="9.26696"
        x2="88.1257"
        y2="17.9086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D8D23C" />
        <stop offset="1" stopColor="#A2D56A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16_37"
        x1="114.401"
        y1="9.26696"
        x2="88.1257"
        y2="17.9086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D8D23C" />
        <stop offset="1" stopColor="#A2D56A" />
      </linearGradient>
    </defs>
  </svg>
);

export default LandingBrandLogoIcon;
