import clsx from 'clsx';

interface StackLoaderIconProps {
  color?: string;
  classes?: string;
}

const StackLoaderIcon = ({ color, classes }: StackLoaderIconProps) => (
  <svg
    className={clsx(color, classes)}
    width="100%"
    height="100%"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="stack-panel-gradient"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        x1="-17.52400016784668"
        y1="-12.345999717712402"
        x2="13.96500015258789"
        y2="-14.368000030517578"
      >
        <stop offset="0%" stopColor="rgb(162,213,106)" />

        <stop offset="50%" stopColor="rgb(189,211,83)" />

        <stop offset="100%" stopColor="rgb(216,210,60)" />
      </linearGradient>
    </defs>

    <g transform="matrix(2, 0, 0, 2, 79.29199981689453, 78.77799987792969)" opacity="0.5">
      <g opacity="1" transform="matrix(1, 0, 0, 1, 0.7080000042915344, 1.222000002861023)">
        <path
          fill="rgb(46,46,46)"
          fillOpacity="1"
          d=" M2.4492935992912173e-15,40 C2.4492935992912173e-15,40 -34.64101791381836,20 -34.64101791381836,20 C-34.64101791381836,20 -34.64101791381836,-20 -34.64101791381836,-20 C-34.64101791381836,-20 -4.287501885642808e-14,-40 -4.287501885642808e-14,-40 C-4.287501885642808e-14,-40 34.64101791381836,-20 34.64101791381836,-20 C34.64101791381836,-20 34.64101791381836,20 34.64101791381836,20 C34.64101791381836,20 2.4492935992912173e-15,40 2.4492935992912173e-15,40z"
        />
      </g>
    </g>

    <g className="animate-slide-bottom-xs">
      <g opacity="0.5" transform="matrix(2, 0, 0, 2, 80, 112.25)">
        <g opacity="1" transform="matrix(1, 0, 0, 1, 0, 0)">
          <path
            fill="url(#stack-panel-gradient)"
            fillOpacity="1"
            d=" M0,-27.5 C0,-27.5 -21.875,-13.5 -21.875,-13.5 C-21.875,-13.5 0,0.25 0,0.25 C0,0.25 21.875,-13.625 21.875,-13.625 C21.875,-13.625 0,-27.5 0,-27.5z"
          />
        </g>
      </g>
    </g>

    <g transform="matrix(2, 0, 0, 2, 80, 107.25)" opacity="0.8">
      <g opacity="1" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path
          fill="url(#stack-panel-gradient)"
          fillOpacity="1"
          d=" M0,-27.5 C0,-27.5 -21.875,-13.5 -21.875,-13.5 C-21.875,-13.5 0,0.25 0,0.25 C0,0.25 21.875,-13.625 21.875,-13.625 C21.875,-13.625 0,-27.5 0,-27.5z"
        />
      </g>
    </g>

    <g className="animate-slide-top-xs">
      <g transform="matrix(2, 0, 0, 2, 80, 102.25)" opacity="1">
        <g opacity="1" transform="matrix(1, 0, 0, 1, 0, 0)">
          <path
            fill="url(#stack-panel-gradient)"
            fillOpacity="1"
            d=" M0,-27.5 C0,-27.5 -21.875,-13.5 -21.875,-13.5 C-21.875,-13.5 0,0.25 0,0.25 C0,0.25 21.875,-13.625 21.875,-13.625 C21.875,-13.625 0,-27.5 0,-27.5z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default StackLoaderIcon;
