import { Outlet } from 'react-router-dom';

import { useTailwindScrollbar } from '@/shared/hooks/use-tailwind-scrollbar';

export const LayoutWrapper = () => {
  useTailwindScrollbar();

  return (
    <div className="min-h-screen h-full flex flex-col items-center bg-onyx text-white">
      <Outlet />
    </div>
  );
};
