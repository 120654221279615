import { useEffect, useMemo, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

export const useContainerSize = <T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size
] => {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;

        setSize({ width, height });
      }),
    []
  );

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (!ref) return;

      observer.unobserve(ref);
    };
  }, [ref, observer]);

  return [setRef, size];
};
